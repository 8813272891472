* {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
}
.contactUs-main {
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  background-color: black;
  padding-top: 5vw;
}
/* .contactUs_main_heading_shadow{
  width: 17vw;
  background-color: rgba(195, 112, 240, 0.6);
  height: 1.2vw;
  margin-left: 44vw;
  margin-bottom: 5vw;
  margin-top: 4vw;
  box-shadow: 0vw 0vw 4vw 1vw rgba(195, 112, 240, 1);
} */
.contactUs-main-h {
  text-align: center;
  padding: 3vw 1vw;
  /* background: rgba(255, 255, 255, 1); */
  /* -webkit-background-clip: text; */
  /* -webkit-text-fill-color: transparent; */
  font-size: 3vw;
  font-family: "Plus Jakarta Sans", sans-serif;
  /* margin: 0px auto; */
  position: relative;
  bottom: 4.3vw;
  text-shadow: 0px 0px 10px rgba(195, 112, 240, 1),
    0px 0px 20px rgba(195, 112, 240, 1), 0px 0px 40px rgba(195, 112, 240, 1),
    0px 0px 80px rgba(195, 112, 240, 1);
  margin-top: 4vw;
}
.contactUs-bg-div {
  background: url("../../assets/image 2900 FEATURES.png");
  width: 100%;
  min-height: 50vw;
  background-repeat: no-repeat;
  margin: 12vw auto;
  display: flex;
  flex-direction: column;
}
.movingCar-img {
  width: 26vw;
  height: auto;
}
.telephoneImg-container {
  display: flex;
  justify-content: center;
 
  
}
.telephoneImg {
  width: 43vw;
  height: 24vw;
position: relative;

  
}

.border_container {
  position: absolute;
  top: 10vw;
  left: 28vw;
  right: 0vw;
  bottom: 0;
  border-radius: 1vw;
  padding: 0.15vw;
  background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  width: 43vw;
  height: 24vw;
}

.contact-rows-div {
  margin-top: 3vw;
  width: 78vw;
  display: flex;
  flex-direction: row;
  gap: 2vw;
  text-align: center;
}
.layer-2contactUs {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -10vw;
}
.contactUs-lyr2-h {
  background: rgba(255, 255, 255, 1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 4vw;
  margin-top: 4vw;
}
.contactUs-lyr2-para {
  color: rgba(255, 255, 255, 1);
  width: 70vw;
  font-size: 1.7vw;
  text-align: center;
}
.each-col-contact{
  width: 30vw;
}
.contact-rows-div h3 {
  font-size: 2vw;
}
.contact-rows-div p {
  font-size: 1.4vw;
  /* margin-top: 12px; */
  text-align: center;
}
.time_in_contact_page{
  width: 28vw;
  margin-top: 0.2vw;
}
.col-contact-1{
  margin-top: 2vw;
}
.col-contact-2{
  margin-top: 2vw;
}
.col-contact-3{
  margin-top: 2vw;
}
.email_in_contact_page{
  margin-top: 0.2vw;
}
.contact_follow_us_div{
  display: flex;
  margin-left: -30vw;
  margin-top: 5vw;
  gap: 5vw;
}
.contact_page_follow_us_btn{
display: flex;
}
.follow_btn{
  background-color: #2C2C2C;
  padding: 0.2vw 0.5vw 0.2vw 0.5vw;
  /* padding-top: 0vw; */
  border-radius: 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.follow_btn::after{
  content: "";
  position: absolute;
  top: -0.7vw;
  left: -0.9vw;
  right: 0vw;
  bottom: 0;
  border-radius: 5vw;
  padding: 0.15vw;
  background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  width: 11vw;
  height: 4.5vw;
}
.contact_page_follow_us_btn p{
  /* margin-top: 2vw; */
  cursor: pointer;
  font-size: 1.5vw;
  font-weight: 400;
  position: relative;
  top: -0.6vw;
  background: linear-gradient(180deg, #790a83 45%, #00f1ff 88%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.contact_page_follow_us_btn > .arrow_right{
margin-left: 0.6vw;
margin-top: 0.3vw;
font-size: 2vw;
}
.contact_page_follow_optns{
  display: flex;
  gap: 1vw;
  margin-top: 0.5vw;
}
.contact_page_follow_optns img{
  width: 2.5vw;
  height: 2.5vw;
  cursor: pointer;
}
.CarGIT {
  /* margin-top: 2.7vw; */
  width: 13vw;
  height: 6vw;
}
.email-gif {
  margin-top: 0.8vw;
  width: 8vw;
  height: 4vw;
  margin-bottom: 1.1vw;
}
.telehone-amimation {
  width: 10vw;
  height: 5vw;
  margin-bottom: 1.1vw;

}

/* contact-form */
.contact-form {
  width: 70vw;
  height: 32vw;
  padding: 3vw;
  background-color: rgba(255, 255, 255, 0.09);
  border: 3px solid transparent;
  /* border-image: linear-gradient(45deg, #29eaff, #e91e63, #9c27b0) 1; */
  /* margin-top: 7vw; */
  /* margin-bottom: 4vw; */
  border-radius: 1vw;
  /* margin-left: -1vw; */
  position: relative;
  top: 5vw;
  right: 0vw;
}
.contact-form ::after {
  content: "";
  position: absolute;
  top: 0vw;
  left: -0.1vw;
  right: 0vw;
  bottom: 0;
  border-radius: 1vw;
  padding: 0.15vw;
  background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  width: 69.8vw;
  height: 31.6vw;
}
.form-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1vw;
}
.input-field,
.textarea-field_contact {
  /* width: 43vw; */
  padding: 0.51vw;
  background-color: rgba(255, 255, 255, 0.09);
  color: #fff;
  font-size: 1.3vw;
  outline: none;

  border: 0.1vw solid transparent;
}

.contact_input_field1 > .input-field {
  width: 30vw;
  border-radius: 2vw;
  padding: 0.7vw;
  position: relative;
}
.input-field::placeholder {
  padding-left: 0.5vw;
}

.contact_input_field2 > .input-field {
  width: 30vw;
  border-radius: 2vw;
  padding: 0.7vw;
  position: relative;
}
.contact_input_field3 > .input-field {
  width: 30vw;
  border-radius: 2vw;
  padding: 0.7vw;
  position: relative;
}
.contact_input_field4 > .input-field {
  width: 30vw;
  border-radius: 2vw;
  padding: 0.7vw;
  position: relative;
}
.contact_textarea > .textarea-field_contact {
  width: 63.6vw;
  border-radius: 2vw;
  padding: 0.7vw;
  position: relative;
}
.textarea-field_contact::placeholder {
  padding-left: 0.5vw;
}

.contact_input_field1::after {
  content: "";
  position: absolute;
  top: 2.9vw;
  left: 2.9vw;
  right: 0;
  bottom: 0;
  border-radius: 2vw;
  padding: 0.15vw;
  background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  width: 29.8vw;
  height: 2.8vw;
}
.contact_input_field2::after {
  content: "";
  position: absolute;
  top: 2.9vw;
  left: 36.6vw;
  right: 0;
  bottom: 0;
  border-radius: 2vw;
  padding: 0.15vw;
  background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  width: 29.8vw;
  height: 2.8vw;
}
.contact_input_field3::after {
  content: "";
  position: absolute;
  top: 6.8vw;
  left: 2.9vw;
  right: 0;
  bottom: 0;
  border-radius: 2vw;
  padding: 0.15vw;
  background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  width: 29.8vw;
  height: 2.8vw;
}
.contact_input_field4::after {
  content: "";
  position: absolute;
  top: 6.9vw;
  left: 36.5vw;
  right: 0;
  bottom: 0;
  border-radius: 2vw;
  padding: 0.15vw;
  background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  width: 30vw;
  height: 2.8vw;
}

.contact_textarea::after {
  content: "";
  position: absolute;
  top: 11vw;
  left: 2.9vw;
  right: 0;
  bottom: 0;
  border-radius: 2vw;
  padding: 0.15vw;
  background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  width: 63.4vw;
  height: 13.7vw;
}

.textarea-field_contact {
  width: 100%;
  resize: none;
  height: 14vw;
}

.input-field::placeholder,
.textarea-field_contact::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.submit-btn {
  width: 100%;
  padding: 1vw;
  font-size: 1.2vw;
  color: #fff;
  background: linear-gradient(90deg, #790a83 28%, #00f1ff 100%);
  border: none;
  border-radius: 0.4vw;
  cursor: pointer;

  width: 12vw;
  margin: 2px auto;
  margin-top: 0.4vw;
}
.car-track {
  border: 2px dashed white;
  position: relative;
  bottom: 1vw;
  width: 13vw;
  margin-left: 5vw;
}
.contactus_icons_movement{
  /* margin-top: 10vw; */
}
.btn-to {
  position: fixed;
  bottom: 1vw;
  right: 5vw;
  font-size: 3vw;
}

/* media query */
@media (max-width: 799px) {
  .contactUs{
    overflow: hidden;
  }
  .contactUs-main {
    width: 100vw;
    min-height: auto;
    padding: 10px;
    margin-top: 16vw;
  }

  .contactUs-main-h {
    font-size: 6vw;
    padding: 10px;
  }

  .contactUs-bg-div {
    width: 100%;
    /* min-height: auto; */
    /* margin: 1vw auto; */
    display: block;
  }

  .telephoneImg {
    width: 80vw;
    height: auto;
  }

  .border_container {
    position: absolute;
    top: 22vw;
    left: 10vw;
    right: 0vw;
    bottom: 0;
    border-radius: 1vw;
    padding: 0.15vw;
    background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    width: 80vw;
    height: 50vw;
  }

  .contact-rows-div {
    display: flex;
    flex-direction: column;
    gap: 6vw;
    width: 90vw;
    margin: 0 auto;
    align-items: center;
    margin-top: 8vw;
  }

  .each-col-contact {
    width: 70%;
    padding: 0 0px;
    text-align: center;
  }

  .CarGIT,
  .email-gif,
  .telehone-amimation {
    width: 27vw;
    height: auto;
    text-align: center;
    margin-bottom: 0;
  }

  .contactUs-lyr2-h {
    font-size: 5vw;
  }

  .contactUs-lyr2-para {
    width: 80%;
    font-size: 3vw;
    text-align: justify;
  }

  .each-col-contact h3 {
    font-size: 5vw;
    width: 63vw;
  }

  .each-col-contact p {
    font-size: 3vw;
    width: 67vw;
  }
  .contact-form {
    width: 80vw;
    height: 77vw;
    padding: 3vw;
    background-color: rgba(255, 255, 255, 0.09);
    border: 3px solid transparent;
    /* border-image: linear-gradient(45deg, #29eaff, #e91e63, #9c27b0) 1; */
    /* margin-top: 7vw; */
    /* margin-bottom: 4vw; */
    border-radius: 1vw;
    /* margin-left: -1vw; */
    position: relative;
    top: 0vw;
    right: 0vw;
  }
  .contact-form ::after {
    content: "";
    position: absolute;
    top: 0vw;
    left: -0.5vw;
    right: 0vw;
    bottom: 0;
    border-radius: 1vw;
    padding: 0.15vw;
    background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    width: 79.8vw;
    height: 76vw;
  }
  .form-group{
    display: flex;
    flex-direction: column;
  }
  .input-field{
    margin-top: 2vw;
  }
  .form-group{
    margin-top: -1vw;
  }
  .contact_input_field1 .input-field{
    width: 72vw;
    height: 7vw;
    font-size: 3vw;
    padding: 0.2vw;
    position: relative;

  }
  .contact_input_field2 .input-field{
    width: 72vw;
    height: 7vw;
    font-size: 3vw;
    padding: 0.5vw;
    position: relative;

  }
  .contact_input_field3 .input-field{
    width: 72vw;
    height: 7vw;
    font-size: 3vw;
    padding: 0.5vw;
    position: relative;

  }
  .contact_input_field4 .input-field{
    width: 72vw;
    height: 7vw;
    font-size: 3vw;
    padding: 0.5vw;
    position: relative;

  }
  .contact_textarea .textarea-field_contact{
    margin-top: 2vw;
    font-size: 4vw;
    height: 25vw;
    width: 72vw;
    padding: 0.5vw;
    position: relative;
  }
  .contact_input_field1::after {
    content: "";
    position: absolute;
    top: 4vw;
    left: 3.9vw;
    right: 0;
    bottom: 0;
    border-radius: 2vw;
    padding: 0.15vw;
    background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    width: 72vw;
    height: 7vw;
  }
  .contact_input_field2::after {
    content: "";
    position: absolute;
    top: 12.8vw;
    left: 2.9vw;
    right: 0;
    bottom: 0;
    border-radius: 2vw;
    padding: 0.15vw;
    background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    width: 72vw;
    height: 6vw;
  }
  .contact_input_field3::after {
    content: "";
    position: absolute;
    top: 22vw;
    left: 2.9vw;
    right: 0;
    bottom: 0;
    border-radius: 2vw;
    padding: 0.15vw;
    background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    width: 72vw;
    height: 6vw;
  }
  .contact_input_field4::after {
    content: "";
    position: absolute;
    top: 30.8vw;
    left: 2.9vw;
    right: 0;
    bottom: 0;
    border-radius: 2vw;
    padding: 0.15vw;
    background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    width: 72vw;
    height: 6vw;
  }
  
  .contact_textarea::after {
    content: "";
    position: absolute;
    top: 39vw;
    left: 2.9vw;
    right: 0;
    bottom: 0;
    border-radius: 2vw;
    padding: 0.15vw;
    background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    width: 72vw;
    height: 24.8vw;
  }
  .submit-btn{
    margin-top: 2vw;
    font-size: 3.5vw;
    width: 35vw;
    border-radius: 1vw;
  }
  .contactus_icons_movement{
    margin-top: -5vw;
  }
  .contact_follow_us_div{
margin-bottom: 5vw;
margin-left: 3vw;
  }
  .follow_btn{
    border-radius: 5vw;
    padding: 1vw;
  }
  .follow_btn::after{
    content: "";
    position: absolute;
    top: -0.9vw;
    left: -0.9vw;
    right: 0vw;
    bottom: 0;
    border-radius: 5vw;
    padding: 0.15vw;
    background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    width: 26.2vw;
    height: 10vw;
  }
  .contact_page_follow_us_btn p{
font-size: 4vw;
  }
  .contact_page_follow_us_btn > .arrow_right{
    margin-left: 0.3vw;
    margin-top: 0vw;
    font-size: 6vw;
    }
    .contact_page_follow_optns{
      margin-top: 0.5vw;
    }
    .contact_page_follow_optns img{
      width: 7vw;
      height: 7vw;
    }

    .car-track {
      border: 2px dashed white;
      position: relative;
      bottom: 1vw;
      width: 50vw;
      margin-left: 5vw;
    }

    .contact_input_field4 .input-field{
      width: 72vw;
      height: 6vw;
      font-size: 3vw;
      padding: 0.5vw;
      position: relative;
  
    }
    .contact_textarea .textarea-field_contact{
      margin-top: 2vw;
      font-size: 3vw;
      height: 25vw;
      width: 72vw;
      padding: 0.5vw;
      position: relative;
    }
    .contact_input_field1::after {
      content: "";
      position: absolute;
      top: 4vw;
      left: 2.9vw;
      right: 0;
      bottom: 0;
      border-radius: 2vw;
      padding: 0.15vw;
      background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      pointer-events: none;
      width: 72vw;
      height: 6vw;
    }

    .telephoneImg-container {
      display: flex;
      justify-content: center;
     margin-top: 5vw;
      
    }
}
