/* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  .hrms-page-container {
    /* min-height: 400vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: black;
  }
  
  .page {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    color: #ffffff; /* White text */
  }
  
  .first-page,
  .second-page,
  .third-page,
  .fourth-page {
    width: 100vw;
  }
  .first-page {
    background-image: url("../../assets/MOTION BACKGROUND 6 FEATURES.png");
    /* background-repeat: no-repeat; */
    background-size: cover;
    /* position: relative; */
  }
  .second-page {
    background-image: url("../../assets/image 2900 FEATURES.png");
  }
  .top-section {
    display: flex;
    justify-content: space-between;
    /* align-items: flex-start; */
    width: 80vw;
    gap: 1vw;
    margin: 1.7vw;
  }
  .bottom-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80vw;
    margin: 2vw;
  }
  .img-container {
    width: 24vw;
    height: 21vw;
    position: relative;
    top: -6vw;
  }
  .middle-section {
    text-align: center;
    width: 30vw;
  }
  .middle-section p {
    text-align: center;
  }
  .middle-section-bottom {
    text-align: center;
    width: 30vw;
  }
  .middle-section-bottom p {
    text-align: center;
  }
  
  .title {
    flex: 1;
    margin: 0 2vw;
  }
  
  #globe{
    width: 70vw;
    height: 40vw;
    position: absolute;
    z-index: 200;
    top: -6vw;
    left: 12.5vw;
    
    
  }
  #globe_3 {
    width: 70vw;
    height: 40vw;
    position: absolute;
    z-index: 200;
    top: 8vw;
    left: 12vw;
 
    
  }
  #globe_2 {
    width: 70vw;
    height: 40vw;
    position: absolute;
    z-index: 200;
    top: 6vw;
    left: 12.5vw;
    
    
  }
  #globe_4 {
    width: 70vw;
    height: 42.5vw;
    position: absolute;
    z-index: 200;
    top: -5vw;
    left: 13vw;
    /* border: 1px solid red;  */
    
  }
  
  h3 {
    font-size: 2vw;
    color: #b9adc2;
  }
  
  p {
    font-size: 1.4vw;
    margin-top: 1vw;
  }
  
  .title-top-feature-page {
    flex: 1;
    margin: 0 1.2vw;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
  }
  .features-1st-page-title-1{
    margin-top: 6vw;
  }
  .features-1st-page-title-2{
    margin-top: 7vw;
  }
  .title-bottom {
    flex: 1;
    margin: 0 1.3vw;
    text-align: center;
  }
  .title-bottom-second-layer {
    flex: 1;
    margin: 0 1.3vw;
    text-align: center;
  }
  .title-bottom-second-layer p {
    /* width: 300px; */
  }
  .title-third-page-feature-page {
    flex: 1;
    margin: 0 1.3vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  /* .globe-img-3 {
    width: 300px;
    height: 300px;
    position: relative;
    top: 60px;
  } */
  .middle-section-third-page {
    text-align: center;
    width: 25vw;
    margin-top: 1vw;
  }
  
  .styled-hr {
    width: 100%;
    height: 0;
    background: #9d72b4a8;
    opacity: 1;
  
    box-shadow: 0vw -0.63vw 1.61vw 0.21vw #c370f0, 
    0vw 0.78vw 1.60vw 0.21vw #c370f0,
    0vw -0.78vw 15.62vw 0.21vw #c370f0, 
    0vw 0.78vw 31.24vw 0.21vw #c370f0,
    0vw -0.78vw 53.44vw 0.21vw #c370f0, 
    0vw 0.78vw 93.70vw 0.21vw #c370f0;
    /* box-shadow: 0px -12px 30.84px 4px #c370f0, 0px 15px 30.68px 4px #c370f0,
      0px -15px 299.88px 4px #c370f0, 0px 15px 599.76px 4px #c370f0,
      0px -15px 1028.16px 4px #c370f0, 0px 15px 1799.28px 4px #c370f0; */
  
    position: relative;
  }
  .para-color {
    background: rgba(255, 255, 255, 1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media screen and (min-width:320px) and (max-width:720px){
    /* .page{
      height: 50vh;
      width: 50vw;
    } */
     .features{
      overflow: hidden;
     }
     #globe {
      width: 64vw;
      height: 66vw;
      position: absolute;
      top: 265vw;
      left: 16vw;
  }
    h3{
      font-size: 3vw;
    }
    p{
      font-size: 3vw;
    }

    #globe_2 {
      width: 45vw;
      height: 54vw;
      position: absolute;
      z-index: 200;
      top: 39vw;
      left: 26vw;
  }
  #globe_3 {
    width: 45vw;
    height: 52vw;
    position: absolute;
    z-index: 200;
    top: 44vw;
    left: 26vw;
}

#globe_4 {
  width: 48vw;
  height: 59.5vw;
  position: absolute;
  z-index: 200;
  top: 20vw;
  left: 24vw;
  /* border: 1px solid red; */
}

    .hrms-page-container {
    /* min-height: 400vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: black;
  }

  .first-page {
    background-image: url("../../assets/MOTION BACKGROUND 6 FEATURES.png");
    /* background-repeat: no-repeat; */
    background-size: cover;
    /* position: relative; */
    /* height: 90vw; */
  }

  .second-page {
    background-image: url("../../assets/image 2900 FEATURES.png");
    /* height: 90vw; */
  }

  .para-color {
    background: rgba(255, 255, 255, 1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 3vw;
    width: 30vw;
  }
  .hrms-page-container{
    display: none;
  }

  }