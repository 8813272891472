body {
  /* height: 100vh; */
  width: 100vw;
  background-color: black;
  overflow-x: hidden;
}
.about-bg {
  background-color: black;
  /* min-height: 100vh; */
  color: #fff;
  /* margin-top: 1vw; */
}
.about-layer-1 {
  width: 100vw;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: aliceblue;
  /* border: 1px solid yellow; */
  padding: 4vw 1vw 1vw 1vw;
}

.fan {
  position: relative;
  width: 11.3vw;
  height: 11.3vw;
  border-radius: 50%;
  /* background: rgb(209, 192, 211); */
  background-color: rgba(177, 177, 177, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: spin 18s infinite linear;
  transform-style: preserve-3d;
}

.fan::after {
  position: absolute;
  content: "";
  width: 10vw;
  height: 10vw;
  background: rgba(127, 18, 139, 1);
  border-radius: 50%;
  border: 0.3 solid rgba(177, 177, 177, 1);
  /* box-shadow: 0px 0px 13px 5px rgba(0, 0, 0, 1); */
  box-shadow: 0px 0px 13px 5px rgba(0, 0, 0, 1),
    0px 16px 4px 0px rgba(0, 0, 0, 0.25) inset;
}

.blade {
  position: absolute;
  width: 18vw;
  height: 8vw;
  border-radius: 5vw;
  transform-origin: 50% 50%;
}

.blade:nth-child(1) {
  transform: rotate(120deg) translateX(1.6vw) translateY(-15vw) rotate(0);
  text-align: center;
}

.blade:nth-child(2) {
  transform: rotate(250deg) translateX(0.5vw) translateY(-15vw) rotate(0);
  text-align: center;
}

.blade:nth-child(3) {
  transform: rotate(10deg) translate(0vw) translateY(-15vw) rotate(0);
  text-align: center;
}

/* span {
  font-size: 2.3vw;
  color: #fff;
  margin-left: 3vw;
} */
.layer-first-blade-content p {
  margin: 0;
  padding: 0;
  font-size: 1.3vw;
  text-align: justify;
}
.layer-first-blade-content {
  font-size: 1.1vw;
  color: #fff;
  /* clip-path: path("M5 -20 C 480 -10, 200 200, 90 230 C 10 400, 600 310, 110 200 C 0 120, -30 -5, 120 0 Z"); */
  width: 22vw;
  margin-bottom: -1vw;
  /* background-color: rgba(127, 18, 139, 1); */
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }

  /* 0% {
    transform: rotate(0deg);
  }
  1.4% {
    transform: rotate(5deg);
  }
  2.8% {
    transform: rotate(10deg);
  }
  4.2% {
    transform: rotate(15deg);
  }
  5.6% {
    transform: rotate(20deg);
  }
  7% {
    transform: rotate(25deg);
  }
  8.4% {
    transform: rotate(30deg);
  }
  9.8% {
    transform: rotate(35deg);
  }
  11.2% {
    transform: rotate(40deg);
  }
  12.6% {
    transform: rotate(45deg);
  }
  14% {
    transform: rotate(50deg);
  }
  15.4% {
    transform: rotate(55deg);
  }
  16.8% {
    transform: rotate(60deg);
  }
  18.2% {
    transform: rotate(65deg);
  }
  19.6% {
    transform: rotate(70deg);
  }
  21% {
    transform: rotate(75deg);
  }
  22.4% {
    transform: rotate(80deg);
  }
  23.8% {
    transform: rotate(85deg);
  }
  25.2% {
    transform: rotate(90deg);
  }
  26.6% {
    transform: rotate(95deg);
  }
  28% {
    transform: rotate(100deg);
  }
  29.4% {
    transform: rotate(105deg);
  }
  30.8% {
    transform: rotate(110deg);
  }
  32.2% {
    transform: rotate(115deg);
  }
  33.6% {
    transform: rotate(120deg);
  }
  35% {
    transform: rotate(125deg);
  }
  36.4% {
    transform: rotate(130deg);
  }
  37.8% {
    transform: rotate(135deg);
  }
  39.2% {
    transform: rotate(140deg);
  }
  40.6% {
    transform: rotate(145deg);
  }
  42% {
    transform: rotate(150deg);
  }
  43.4% {
    transform: rotate(155deg);
  }
  44.8% {
    transform: rotate(160deg);
  }
  46.2% {
    transform: rotate(165deg);
  }
  47.6% {
    transform: rotate(170deg);
  }
  49% {
    transform: rotate(175deg);
  }
  50.4% {
    transform: rotate(180deg);
  }
  51.8% {
    transform: rotate(185deg);
  }
  53.2% {
    transform: rotate(190deg);
  }
  54.6% {
    transform: rotate(195deg);
  }
  56% {
    transform: rotate(200deg);
  }
  57.4% {
    transform: rotate(205deg);
  }
  58.8% {
    transform: rotate(210deg);
  }
  60.2% {
    transform: rotate(215deg);
  }
  61.6% {
    transform: rotate(220deg);
  }
  63% {
    transform: rotate(225deg);
  }
  64.4% {
    transform: rotate(230deg);
  }
  65.8% {
    transform: rotate(235deg);
  }
  67.2% {
    transform: rotate(240deg);
  }
  68.6% {
    transform: rotate(245deg);
  }
  70% {
    transform: rotate(250deg);
  }
  71.4% {
    transform: rotate(255deg);
  }
  72.8% {
    transform: rotate(260deg);
  }
  74.2% {
    transform: rotate(265deg);
  }
  75.6% {
    transform: rotate(270deg);
  }
  77% {
    transform: rotate(275deg);
  }
  78.4% {
    transform: rotate(280deg);
  }
  79.8% {
    transform: rotate(285deg);
  }
  81.2% {
    transform: rotate(290deg);
  }
  82.6% {
    transform: rotate(295deg);
  }
  84% {
    transform: rotate(300deg);
  }
  85.4% {
    transform: rotate(305deg);
  }
  86.8% {
    transform: rotate(310deg);
  }
  88.2% {
    transform: rotate(315deg);
  }
  89.6% {
    transform: rotate(320deg);
  }
  91% {
    transform: rotate(325deg);
  }
  92.4% {
    transform: rotate(330deg);
  }
  93.8% {
    transform: rotate(335deg);
  }
  95.2% {
    transform: rotate(340deg);
  }
  96.6% {
    transform: rotate(345deg);
  }
  98% {
    transform: rotate(350deg);
  }
  100% {
    transform: rotate(360deg);
  } */
}

#p_1 {
  margin-left: 4.5vw;
  /* text-align: justify; */
  word-spacing: 0.3vw;
}
#p_2 {
  margin-left: 4.5vw;
  /* text-align: justify; */
  word-spacing: 0.5vw;
}
#p_3 {
  margin-left: 4.2vw;
  /* text-align: justify; */
  word-spacing: 0.5vw;
}
#p_4 {
  margin-left: 4.7vw;
  /* text-align: justify; */
  word-spacing: 0.7vw;
}
#p_5 {
  margin-left: 5.5vw;
  /* text-align: justify; */
  word-spacing: 1.5vw;
}

.img-logo-company {
  width: 8.4vw;
  height: auto;
  z-index: 10;
  margin-bottom: 1vw;
  position: relative;
  left: 46vw;
  top: -26vw;
}
/* layer-2 code start */
.about-layer-2 {
  width: 100vw;
  padding-left: 3vw;
  /* min-height: 100vh; */
  margin-top: 9vw;
}
.about-layer-2 .s-image {
  width: 95vw;
  height: 80vw;
  position: relative;
  z-index: 10;
  top: -6vw;
}
.about_layer2_shadow {
  position: absolute;
  background: radial-gradient(
    ellipse at right,
    rgba(144, 52, 193, 0.7) 50%,
    transparent 80%
  );
  top: 50%;
  left: auto; /* Removing left */
  right: -10%; /* Ensure the glow is offset from the right */
  width: 30%;
  height: 150%;
  transform: perspective(10vw) rotateY(-15deg) scale(0.7, 1); /* Rotating opposite for the right side */
  filter: blur(30px);
  opacity: 0.7;
}
.about-layer-2 .layerimg-Abt {
  width: 23vw;
  height: 22vw;
  position: absolute;
  left: 3.2vw;
  top: 63vw;
  z-index: 20;
  /* border-radius: 3vw; */
  border-top-left-radius: 3vw;
  border-bottom-left-radius: 3vw;
}
.layerimg-Abt2 {
  width: 23.7vw;
  height: 22.5vw;
  position: absolute;
  right: 1vw;
  top: 96.5vw;
  z-index: 30;
  border-top-right-radius: 3vw;
  border-bottom-right-radius: 3vw;
}
.abt-layer2-right-content {
  width: 50vw;
  position: absolute;
  right: 15vw;
  top: 63vw;
  z-index: 2;
}
.abt-layer2-right-content p {
  font-size: 1.3vw;
  font-weight: 600;
  background: rgba(255, 255, 255, 1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: justify;
  margin-top: 0vw;
}
#p_content{
  margin-top: 3vw;
}
.abt-layer2-left-content {
  width: 50vw;
  position: absolute;
  left: 14vw;
  top: 98vw;
  z-index: 2;
  background: rgba(255, 255, 255, 1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2vw;
}
.abt-layer2-left-content p {
  background: rgba(255, 255, 255, 1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.4vw;
  font-weight: 600;
  text-align: justify;
}
.globe-img-1 {
  display: flex;
  width: 12vw;
  height: 12vw;
  position: absolute;
  z-index: 15;
  right: 0%;
  /* top: 770px; */
  top: 54vw;
  gap: 1vw;
}
.main_icons_div {
  display: flex;
}
.about_icon2 {
  position: relative;
  top: -83vw;
  left: 70vw;
}
.about_icon3 {
  position: relative;
  top: -84vw;
  left: 50vw;
}
.about_icon4 {
  position: relative;
  top: -83.5vw;
  left: 30vw;
}
.about_icon5{
  position: relative;
  top: -83vw;
  left: 10vw;
}
.about_icon6{
  position: relative;
  top: -82vw;
  left: -10vw;
}
.about_icon7{
position: relative;
top: -51vw;
left: -16vw;
}
.about_icon8{
position: relative;
top: -50vw;
left: -8vw;
}
.about_icon9{
position: relative;
top: -50vw;
left: -1vw;
}
.about_icon10{
position: relative;
top: -49.5vw;
left: 6vw;
}
.about_icon11{
position: relative;
top: -49vw;
left: 13vw;
}
.about_icon12{
position: relative;
top: -15.5vw;
left: 4vw;
}
.about_icon13{
position: relative;
top: -15.5vw;
left: -20vw;
}
.about_icon14{
position: relative;
top: -15vw;
left: -42vw;
}
.about_icon15{
position: relative;
top: -15vw;
left: -63vw;
}
.about_icon16{
position: relative;
top: -15vw;
left: -82vw;
}
#icon{
  font-size: 6vw ;
}
#icon_1{
  font-size: 7vw ;
}
#icon_2{
font-size: 5vw ;
}
/* .about_icon17{
position: relative;
top: -15vw;
left: -106vw;
} */
/* .icon{
  font-size: 5vw;
} */
.my-svg {
  position: absolute;
  z-index: 12;
  top: 59vw;
  left: 0;
}
.icons-hr-line {
  width: 100%;
  width: 100vw;
  border: none; /* Remove the default border */
  height: 2px; /* Set the thickness of the line */
  background-color: rgba(
    121,
    10,
    131,
    1
  ); /* Set the background color (color of the line) */
}

/* icons-scrolling css code */
.clients {
  display: flex;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 12px;
  margin-bottom: 12px;
}

.clients > .clients-img {
  position: absolute;
  width: 40vw;
}

.claint-images-1 {
  height: 15vw;
  width: 15vw;
}

.Client-Cards-MMM {
  display: flex;
  /* gap: 10px; */
  position: relative;
  animation-play-state: running; /* Ensure animation starts by default */
  animation: ClientScrolling 60s linear infinite;
}

@keyframes ClientScrolling {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

.client-section-container {
  display: flex;
  flex-direction: column;
}

.client-container-box {
  width: 20vw;
  height: 10vw;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
}

.client-container-box p {
  text-align: justify;
}

.claint-images-1 {
  width: 11vw;
  height: 5vw;
}
.half-globe-container {
  margin: 1.5vw 0.2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin-top: -10vw;
}
.half-globe {
  width: 44%;
  height: 100%;
  transform: rotate(-8deg);
}
.half-globe-container h1 {
  position: absolute;
  top: 70%;
  background: linear-gradient(0deg, #ffffff 30.53%, rgba(0, 0, 0, 0) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 2vw;
  font-size: 2.4vw;
}
@media screen and (min-width: 320px) and (max-width: 720px) {
  .aboutUs {
    overflow: hidden;
  }
  .about-layer-1 {
    height: auto;
  }
  .fan {
    position: relative;
    width: 11.3vw;
    height: 11.3vw;
    border-radius: 50%;
    /* background: rgb(209, 192, 211); */
    background-color: rgba(177, 177, 177, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: spin 20s infinite linear;
    transform-style: preserve-3d;
  }

  .fan::after {
    position: absolute;
    content: "";
    width: 30vw;
    height: 30vw;
    background: rgba(127, 18, 139, 1);
    border-radius: 50%;
    border: 0.3 solid rgba(177, 177, 177, 1);
    /* box-shadow: 0px 0px 13px 5px rgba(0, 0, 0, 1); */
    box-shadow: 0px 0px 13px 5px rgba(0, 0, 0, 1),
      0px 16px 4px 0px rgba(0, 0, 0, 0.25) inset;
  }
  .img-logo-company {
    height: 15vw;
    width: 23vw;
    position: relative;
    top: -49vw;
    left: 38vw;
  }
  .blade {
    width: 30vw;
    height: 47vw;
  }
  .blade span {
    font-size: 4vw;
  }
  .blade p {
    font-size: 2.5vw;
  }

  .about-layer-2 {
    width: 100vw;
    padding-left: 3vw;
    /* min-height: 100vh; */
    margin-top: 26vw;
    height: 90vh;
  }
  .about-layer-2 .layerimg-Abt {
            width: 80vw;
        height: 51vw;
        position: absolute;
        left: 10vw;
        top: 168.5vw;
        z-index: 2;
        border-radius: 1vw;
      
  }
  .abt-layer2-right-content {
    position: absolute;
    top: 221vw;
    width: 80vw;
    height: auto;
    left: 10vw;
    overflow: scroll;

  
 
  }


  .abt-layer2-right-content p {
    font-size: 3vw;
    height: 60vw;
    overflow: scroll;
    width: 80vw;
 

   

  }
  .about-layer-2 .layerimg-Abt2 {
    width: 80vw;
        height: 50vw;
        position: absolute;
        left: 10vw;
        top: 282vw;
        z-index: 2;
        border-radius: 1vw;
  }
  #p_content{
    margin-top: 0vw;
  }
  .abt-layer2-left-content {
    width:80vw;
    position: absolute;
    top: 333vw;
    left: 10vw;
    height: auto;
    /* overflow: scroll; */
  }
  .abt-layer2-left-content p {
    font-size: 3vw;
    height: auto;
    /* overflow: scroll; */
    width: 80vw;
  }
  .globe-img-1 {
    display: flex;
    width: 12vw;
    height: 12vw;
    position: absolute;
    z-index: 15;
    right: 4vw;
    top: 695px;
    
    gap: 1vw;
  }
  .half-globe {
    width: 60vw;
    height: 30vw;
  }
  .half-globe-container h1 {
    font-size: 3.4vw;
    position: relative;
    top: -6vw;
  }

  .about-layer-2 .s-image {
    width: 95vw;
    height:205vw;
    position: relative;
    z-index: 10;
    top: 0vw;
    display: none;
  }
}
@media screen and (max-width: 320px) {
  globe-img-1 {
    top: 35vh;
  }
  .my-svg {
    top: 35vh;
  }
}
@media screen and (max-width: 420px) {
  globe-img-1 {
    top: 35vh;
  }
  .my-svg {
    top: 35vh;
  }
}
@media screen and (max-width: 720px) {
  globe-img-1 {
    top: 35vh;
  }
  .my-svg {
    top: 35vh;
  }

  #icon{
  font-size: 10vw ;
  display: none;
}
#icon_1{
  font-size: 14vw ;
  display: none;
}
#icon_2{
font-size: 10vw ;
display: none;
}

.about_icon2 {
  position: relative;
  top: -133vw;
  left: 67vw;
  display: none;
}
.about_icon3 {
  position: relative;
  top: -134.5vw;
  left: 40vw;
  display: none;
}

.about_icon4 {
  position: relative;
  top: -132.5vw;
  left: 14vw;
  display: none;
}

.about_icon5 {
  position: relative;
  top: -132.5vw;
  left: -10vw;
  display: none;
}

.about_icon6 {
  position: relative;
  top: -130vw;
  left: -33vw;
  display: none;
}
.about_icon7 {
  position: relative;
  top: -78vw;
  left: -16vw;
  display: none;
}

.about_icon8 {
  position: relative;
  top: -77vw;
  left: 5vw;
  display: none;
}

.about_icon9 {
  position: relative;
  top: -80vw;
  left: -25vw;
  display: none;
}

.about_icon10 {
  position: relative;
  top: -80vw;
  left: -66vw;
  display: none;
}

.about_icon11 {
  position: relative;
  top: -80vw;
  left: -90vw;
  display: none;
}

.about_icon12 {
  position: relative;
  top: -24.5vw;
  left: -45vw;
  display: none;
}


.about_icon13 {
  position: relative;
  top: -24.5vw;
  left: -76vw;
  display: none;
}

.about_icon14 {
  position: relative;
  top: -23vw;
  left: -108vw;
  display: none;
}

.about_icon15 {
  position: relative;
  top: -21vw;
  left: -135vw;
  display: none;
}

.about_icon16 {
  position: relative;
  top: -21vw;
  left: -160vw;
  display: none;
}

.about-bg {
  background-color: black;
  /* min-height: 100vh; */
  color: #fff;
  margin-top: 18vw;
}

.half-globe-container {
  margin: 1.5vw 0.2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin-top: 40vw;
}



}
