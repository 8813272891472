.affiliate-main-heading {
  padding: 5vw 1vw 1vw 1vw;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  font-size: 3vw;
  font-weight: 700;
  text-shadow: 0px 0px 10px rgba(195, 112, 240, 1),
    0px 0px 20px rgba(195, 112, 240, 1), 0px 0px 40px rgba(195, 112, 240, 1),
    0px 0px 80px rgba(195, 112, 240, 1);
}
.bg-content-Affiliate-Program {
  width: 100vw;
  min-height: 100vh;
  margin: 0.5vw auto;
  background-image: url("../../assets/affilited-bg.png");
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-x: hidden;
}
.affiliate-section-1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3vw 3vw 0vw 3vw;
  color: white;
  min-height: 30vw;
  width: 90vw;
  border-radius: 0.8vw;
}

.affiliate-content {
  display: flex;
  flex-direction: column;
  max-width: 45vw;
  align-items: flex-start;
}

.small-text {
  font-size: 1.8vw;
  margin-bottom: 0.4vw;
}

.main-heading {
  font-size: 4vw;
  margin: 0.5vw 0;
  font-weight: 700;
}
.affiliate_side p {
  font-size: 1.2vw;
  /* background: linear-gradient(180deg, #790a83 45%, #00f1ff 88%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: #fff;
  position: fixed;
  top: 8vw;
  left: 0vw;
  z-index: 100;
  padding: 0.5vw 1.5vw;
  border-top-right-radius: 2vw;
  border-bottom-right-radius: 2vw;

  background-color: #790a83;
}
.description {
  font-size: 2vw;
  margin-bottom: 1.5vw;
}

.affiliate-button {
  padding: 0.6vw 1.4vw;
  font-size: 1.5vw;
  color: white;
  background: linear-gradient(90deg, #790a83 28%, #00f1ff 100%);
  border: none;
  border-radius: 0.8vw;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
  margin-top: 2vw;
  cursor: pointer;
}

.btn-border-affiliate {
  position: relative;
  top: -3.5vw;
  left: -1vw;
  width: 16vw;
  border-radius: 0.8vw;
  height: 3vw;
  border: 0.2vw solid white;
  cursor: pointer;
}
.image-placeholder {
  width: 50vw;
  height: 26vw;
  /* border: 0.2vw solid white; */
}
.image-placeholder img {
  width: 38vw;
  height: 23vw;
  /* border: 0.2vw solid white; */
}
/* layer 2  */
.grid-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3vw;
  color: white;
  min-height: 30vw;
  width: 90vw;
  border-radius: 0.6vw;
  margin-bottom: 6vw;
}

.text-content {
  display: flex;
  flex-direction: column;
  max-width: 50%;
}

.heading-large {
  font-size: 3.5vw;
  color: rgba(255, 255, 255, 1);
  margin: 1vw 0;
  font-weight: bold;
}

.business-grid {
  /* display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.3vw;
  margin-top: 1.5vw; */
}
.business_grid_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.grid-button1 {
  padding: 1vw 0.3vw;
  font-size: 1.5vw;
  color: white;
  background: rgba(20, 20, 20, 1);
  border: none;
  border-radius: 0.8vw;
  cursor: pointer;
  font-family: "Inter";
  position: relative;
  justify-content: center;
  width: 20vw;
}
.grid-button2 {
  padding: 1vw 0.3vw;
  font-size: 1.5vw;
  color: white;
  background: rgba(20, 20, 20, 1);
  border: none;
  border-radius: 0.8vw;
  cursor: pointer;
  font-family: "Inter";
  position: relative;
  justify-content: center;
  width: 20vw;
}
.grid-button3 {
  padding: 1vw 0.3vw;
  font-size: 1.5vw;
  color: white;
  background: rgba(20, 20, 20, 1);
  border: none;
  border-radius: 0.8vw;
  cursor: pointer;
  font-family: "Inter";
  position: relative;
  justify-content: center;
  width: 20vw;
}
.grid-button4 {
  padding: 1vw 0.3vw;
  font-size: 1.5vw;
  color: white;
  background: rgba(20, 20, 20, 1);
  border: none;
  border-radius: 0.8vw;
  cursor: pointer;
  font-family: "Inter";
  position: relative;
  justify-content: center;
  width: 20vw;
}
.grid-button5 {
  padding: 1vw 0.3vw;
  font-size: 1.5vw;
  color: white;
  background: rgba(20, 20, 20, 1);
  border: none;
  border-radius: 0.8vw;
  cursor: pointer;
  font-family: "Inter";
  position: relative;
  justify-content: center;
  width: 20vw;
}
.grid-button6 {
  padding: 1vw 0.3vw;
  font-size: 1.5vw;
  color: white;
  background: rgba(20, 20, 20, 1);
  border: none;
  border-radius: 0.8vw;
  cursor: pointer;
  font-family: "Inter";
  position: relative;
  justify-content: center;
  width: 20vw;
}
.business-grid1 {
  width: 22vw;
  text-align: center;
}
.grid-button1::after {
  content: "";
  position: absolute;
  top: 0vw;
  left: -0.1vw;
  right: 0vw;
  bottom: 0;
  border-radius: 1vw;
  padding: 0.15vw;
  background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  width: 19.8vw;
  height: 3.4vw;
}
.grid-button2::after {
  content: "";
  position: absolute;
  top: 0vw;
  left: -0.1vw;
  right: 0vw;
  bottom: 0;
  border-radius: 1vw;
  padding: 0.15vw;
  background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  width: 19.8vw;
  height: 3.4vw;
}
.grid-button3::after {
  content: "";
  position: absolute;
  top: 0vw;
  left: -0.1vw;
  right: 0vw;
  bottom: 0;
  border-radius: 1vw;
  padding: 0.15vw;
  background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  width: 19.8vw;
  height: 3.4vw;
}
.grid-button4::after {
  content: "";
  position: absolute;
  top: 0vw;
  left: 0vw;
  right: 0vw;
  bottom: 0;
  border-radius: 1vw;
  padding: 0.15vw;
  background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  width: 19.8vw;
  height: 3.4vw;
}
.grid-button5::after {
  content: "";
  position: absolute;
  top: 0vw;
  left: 0vw;
  right: 0vw;
  bottom: 0;
  border-radius: 1vw;
  padding: 0.15vw;
  background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  width: 19.8vw;
  height: 3.4vw;
}
.grid-button6::after {
  content: "";
  position: absolute;
  top: 0vw;
  left: 0vw;
  right: 0vw;
  bottom: 0;
  border-radius: 1vw;
  padding: 0.15vw;
  background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  width: 19.8vw;
  height: 3.4vw;
}

/* .grid-outer-border-1 {
  width: 21.5vw;
  height: 3.5vw;
  border: 1px solid white;
  position: absolute;
  border: 0.2vw solid transparent;
  border-image: linear-gradient(
      145.02deg,
      #ffffff 4.74%,
      #790a83 35.52%,
      #00f1ff 95.26%
    )
    1;
} */
/* .grid-outer-border-11 {
  width: 20vw;
  height: 3.9vw;
  border: 1px solid white;
  position: absolute;
  left: 29.3vw;
  bottom: -24.7vw;
  border: 2px solid transparent;
  border-image: linear-gradient(
      145.02deg,
      #ffffff 4.74%,
      #790a83 35.52%,
      #00f1ff 95.26%
    )
    1;
} */
/* .grid-outer-border-2 {
  width: 20vw;
  height: 3.9vw;
  border: 1px solid white;
  position: absolute;
  left: 7.4vw;
  bottom: -29.8vw;
  border: 2px solid transparent;
  border-image: linear-gradient(
      145.02deg,
      #ffffff 4.74%,
      #790a83 35.52%,
      #00f1ff 95.26%
    )
    1;
} */
/* .grid-outer-border-22 {
  width: 20vw;
  height: 3.9vw;
  border: 1px solid white;
  position: absolute;
  left: 29.3vw;
  bottom: -29.9vw;
  border: 2px solid transparent;
  border-image: linear-gradient(
      145.02deg,
      #ffffff 4.74%,
      #790a83 35.52%,
      #00f1ff 95.26%
    )
    1;
} */
/* .grid-outer-border-3 {
  width: 20vw;
  height: 3.9vw;
  border: 1px solid white;
  position: absolute;
  left: 7.4vw;
  bottom: -34.7vw;
  border: 2px solid transparent;
  border-image: linear-gradient(
      145.02deg,
      #ffffff 4.74%,
      #790a83 35.52%,
      #00f1ff 95.26%
    )
    1;
} */
/* .grid-outer-border-33 {
  width: 20vw;
  height: 3.9vw;
  border: 1px solid white;
  position: absolute;
  left: 29.1vw;
  bottom: -34.7vw;
  border: 2px solid transparent;
  border-image: linear-gradient(
      145.02deg,
      #ffffff 4.74%,
      #790a83 35.52%,
      #00f1ff 95.26%
    )
    1;
} */

.visual-box {
  width: 39vw;
  height: 28vw;
  /* border: 2px solid white; */
}
.visual-box img {
  width: 39vw;
  height: 23vw;
  margin-top: 4vw;
  /* border: 2px solid white; */
}
/* layer-3 */
.affiliate-container {
  width: 84vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2vw;
  background: rgba(45, 45, 45, 1);
  color: white;
  border-radius: 1vw;
  /* margin-bottom: 4vw; */
  position: relative;
}
.affiliate-container::after {
  content: "";
  position: absolute;
  top: 0vw;
  left: 0vw;
  right: 0vw;
  bottom: 0;
  border-radius: 1vw;
  padding: 0.15vw;
  background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  /* width: 89.7vw; */
  width: auto;
  height: auto;
}
.affiliate-heading {
  font-size: 2.7vw;
  font-weight: bold;
  margin-bottom: 2vw;
  background: rgba(255, 255, 255, 1);

  -webkit-background-clip: text;
  color: transparent;
}

.info-card-section {
  display: flex;
  justify-content: space-between;
  width: 80vw;
  max-width: 110vw;
}
.info-card {
  background-color: #1a1a1a;
  border: 2px solid transparent;
  border-image: linear-gradient(90deg, #790a83, #00f1ff) 1;
  padding: 1.4vw;
  max-width: 35vw;
  text-align: start;
  margin: 0 1vw;
  background: linear-gradient(69.78deg, #790a83 24.12%, #00f1ff 105.54%);
  transition: transform 0.3s ease;
}
.info-card:hover {
  /* Zoom in effect */
  transform: scale(1.1);
}
.info-card-title {
  font-size: 1.4vw;
  font-weight: bold;
  margin-bottom: 15px;
  background: rgba(255, 255, 255, 1);
  -webkit-background-clip: text;
}
.info-card-description {
  font-size: 1.2vw;
  line-height: 1.6;
  background: rgba(255, 255, 255, 1);
  -webkit-background-clip: text;
}
/* layer-4 css code */
.affiliate-section-layer-4 {
  width: 81vw;
  margin: 4vw 1vw;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1vw;
  color: #fff;
}
.affiliate-cards-layer-4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 9vw;
}
.card-layer-4 {
  width: 21vw;
  position: relative;
  /* bottom: 4vw; */
  left: 2vw;
}
.affiliate-title-layer-4 {
  font-size: 4vw;
  font-weight: 600;
  background: rgba(255, 255, 255, 1);
  -webkit-background-clip: text;
}
.affiliate-description-layer-4 {
  font-size: 1.5vw;
  background: rgba(255, 255, 255, 1);
  -webkit-background-clip: text;
}
.affiliate-image-layer-4 {
  width: 18vw;
  height: 19vw;
  z-index: 2;
  position: relative;
  /* top: 7.5vw; */
  top: 8vw;
}
.affiliate-image-layer-3 {
  width: 18vw;
  height: 19vw;
  z-index: 2;
  position: relative;
  /* top: 7.5vw; */
  top: 8vw;
}
.affiliate-card-1img-bg {
  z-index: -1;
  position: relative;
  top: -7.5vw;
  left: -2.1vw;
  width: 22vw;
  height: 15.3vw;
  background: rgba(20, 20, 20, 1);
  border: 2px solid transparent;
  border-image: linear-gradient(90deg, #790a83, #00f1ff) 1;
}
.card-content-affiliate {
  position: relative;
  top: -6vw;
  left: -1.4vw;
}
.card-title-layer-4 {
  background: rgba(255, 255, 255, 1);
  -webkit-background-clip: text;
  font-size: 2vw;
  font-weight: 600;
  width: 28vw;
  height: 5vw;
}
.card-description-layer-4 {
  font-size: 1.3vw;
  font-weight: 300;
  background: rgba(255, 255, 255, 1);
  -webkit-background-clip: text;
}
.Affliate_program_iconsMovement {
  margin-top: -4vw;
}

.application-modal_ap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  font-family: "Helvetica Now Display Medium";
}
.application-modal-content_ap {
  background-color: rgb(0, 0, 0);
  padding: 20px;
  border-radius: 8px;
  width: 90vw;
  max-width: 70%;
  max-height: 80%;
  overflow-y: auto;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
  font-family: "Helvetica Now Display Medium";
}
.close-button_ap {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
  color: black;
  font-family: "Helvetica Now Display Extra Bold";
}
.application-modal-content_ap form {
  display: flex;
  flex-direction: column;
}

.application-modal-content_ap form label {
  margin-top: 10px;
  margin-bottom: 5px;
}

.application-modal-content_ap form input {
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  border: 1px solid rgba(121, 10, 131, 1);
  background-color: black;
}
.application-modal-content_ap form input:focus {
  border: 1px solid rgba(121, 10, 131, 1);
  outline: none;
}

.application-modal_ap ::after {
  content: "";
  position: absolute;
  top: 0vw;
  left: 0vw;
  right: 0;
  bottom: 0;
  border-radius: 0.5vw;
  padding: 0.15vw;
  background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  width: auto;
  height: auto;
}
.application-modal-content_ap form button {
  padding: 10px;
  background: linear-gradient(90deg, #790a83 28%, #00f1ff 100%);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  font-family: "Helvetica Now Display Extra Bold";
  font-size: 1.6vw;
  font-weight: bold;
}
/* media query */
@media (max-width: 769px) {
  .affiliate {
    overflow: hidden;
    margin-top: 20vw;
  }
  /* Main Heading */
  .affiliate-main-heading {
    font-size: 5vw;
    padding: 8vw 2vw 1vw 2vw;
  }

  /* Section 1 */
  .affiliate-section-1 {
    flex-direction: column-reverse;
    align-items: center;
    padding: 1vw 2vw 5vw 2vw;
  }

  .affiliate-content {
    max-width: 80%;
    align-items: center;
    text-align: center;
    /* height: 70vw; */
    margin-top: 10vw;
  }

  .small-text {
    font-size: 3vw;
    width: 80vw;
    margin-bottom: 2vw;
  }

  .main-heading {
    font-size: 5vw;
    margin: 0vw 0;
  }

  .ap_description {
    font-size: 3vw;
    text-align: justify;
    margin-bottom: 4vw;
  }

  .affiliate-button {
    font-size: 4vw;
    padding: 2vw 4vw;
  }

  .btn-border-affiliate {
    width: 43vw;
    height: 8.6vw;
    top: -10vw;
    left: -2.2vw;
    border: 0.3vw solid white;
  }

  .image-placeholder {
    width: 80vw;
    height: 35vw;
    margin-top: 5vw;
  }

  /* Grid Section */
  .grid-section {
    flex-direction: column-reverse;
    align-items: center;
    padding: 2vw 2vw 5vw 2vw;
    margin-top: -8vw;
  }

  .text-content {
    max-width: 100%;
    text-align: center;
  }

  .heading-large {
    font-size: 5vw;
    margin-bottom: 4vw;
  }

  .business-grid {
    grid-template-columns: 1fr;
    gap: 4vw;
  }
  .business_grid_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: -58vw;
  }
  .grid-button1 {
    font-size: 3vw;
    padding: 3vw 2vw;
    width: 70vw;
    margin: 0 auto;
    margin-top: 2vw;
  }
  .grid-button2 {
    font-size: 3vw;
    padding: 3vw 2vw;
    width: 70vw;
    margin: 0 auto;
    margin-top: 2vw;
  }
  .grid-button3 {
    font-size: 3vw;
    padding: 3vw 2vw;
    width: 70vw;
    margin: 0 auto;
    margin-top: 2vw;
  }
  .grid-button4 {
    font-size: 3vw;
    padding: 3vw 2vw;
    width: 70vw;
    margin: 0 auto;
    margin-top: 2vw;
  }
  .grid-button5 {
    font-size: 3vw;
    padding: 3vw 2vw;
    width: 70vw;
    margin: 0 auto;
    margin-top: 2vw;
  }
  .grid-button6 {
    font-size: 3vw;
    padding: 3vw 2vw;
    width: 70vw;
    margin: 0 auto;
    margin-top: 2vw;
  }
  .grid-button1::after {
    content: "";
    position: absolute;
    top: 0vw;
    left: 0vw;
    right: 0vw;
    bottom: 0;
    border-radius: 1vw;
    padding: 0.2vw;
    background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    width: 70vw;
    height: 9.5vw;
  }
  .grid-button2::after {
    content: "";
    position: absolute;
    top: 0vw;
    left: 0vw;
    right: 0vw;
    bottom: 0;
    border-radius: 1vw;
    padding: 0.2vw;
    background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    width: 70vw;
    height: 9.5vw;
  }
  .grid-button3::after {
    content: "";
    position: absolute;
    top: 0vw;
    left: 0vw;
    right: 0vw;
    bottom: 0;
    border-radius: 1vw;
    padding: 0.2vw;
    background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    width: 70vw;
    height: 9.5vw;
  }
  .grid-button4::after {
    content: "";
    position: absolute;
    top: 0vw;
    left: 0vw;
    right: 0vw;
    bottom: 0;
    border-radius: 1vw;
    padding: 0.2vw;
    background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    width: 70vw;
    height: 9.5vw;
  }
  .grid-button5::after {
    content: "";
    position: absolute;
    top: 0vw;
    left: 0vw;
    right: 0vw;
    bottom: 0;
    border-radius: 1vw;
    padding: 0.2vw;
    background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    width: 70vw;
    height: 9.5vw;
  }
  .grid-button6::after {
    content: "";
    position: absolute;
    top: 0vw;
    left: 0vw;
    right: 0vw;
    bottom: 0;
    border-radius: 1vw;
    padding: 0.2vw;
    background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    width: 70vw;
    height: 9.5vw;
  }

  .grid-outer-border-1,
  .grid-outer-border-11,
  .grid-outer-border-2,
  .grid-outer-border-22,
  .grid-outer-border-3,
  .grid-outer-border-33 {
    display: none;
  }

  .visual-box {
    width: 70vw;
    height: 50vw;
    margin-top: 5vw;
  }

  /* Affiliate Container */
  .affiliate-container {
    width: 80vw;
    padding: 5vw 2vw;
  }

  .affiliate-heading {
    font-size: 5vw;
    margin-bottom: 5vw;
    text-align: center;
  }

  .info-card-section {
    flex-direction: column;
    align-items: center;
    display: flex;
    width: 70vw;
    max-width: 110vw;
  }

  .info-card {
    max-width: 100%;
    margin-bottom: 5vw;
    padding: 4vw;
  }

  .info-card-title {
    font-size: 5vw;
    margin-bottom: 3vw;
  }

  .info-card-description {
    font-size: 3vw;
    text-align: justify;
    word-wrap: -1vw;
    /* line-height: 7vw; */
  }

  /* Layer 4 */
  .affiliate-section-layer-4 {
    width: 90vw;
    margin: 5vw 0;
    align-items: center;
    text-align: center;
  }

  .affiliate-title-layer-4 {
    font-size: 5vw;
  }

  .affiliate-description-layer-4 {
    font-size: 3vw;
    margin-bottom: 5vw;
    width: 70vw;
    text-align: justify;
    /* word-spacing: -2vw; */
    overflow: scroll;
    height: 37vw;
  }

  .affiliate-cards-layer-4 {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    gap: 15vw;
    padding: 2vw;

    /* border: 1px solid red; */
    width: 80vw;
    margin-top: 20vw;
  }
  #card_pera {
    margin-top: -6vw;
    margin-left: 0;
  }

  .card-layer-4 {
    width: 100vw;
    left: 0;
    bottom: 0;
    margin-bottom: 0vw;
    margin-top: 0vw;
    height: 106vw;
    transition: all 2s ease-in-out;
    /* border: 1px solid green; */
  }
  /* .card-layer-4:nth-of-type(1){
  margin: 0 15vw 0 0 ;
  } */
  /* .card-layer-4:nth-of-type(2){
   margin: 0 10vw;
  } */
  .card-layer-4:nth-of-type(3){
    margin-right: -50vw;
  }

  .affiliate-card-1img-bg {
    z-index: -1;
    position: relative;
    top: -7.5vw;
    left: -2.1vw;
    width: 22vw;
    height: 1.3vw;
    background: rgba(20, 20, 20, 1);
    border: 2px solid transparent;
    border-image: linear-gradient(90deg, #790a83, #00f1ff) 1;
    border: 5px solid red;
  }

  .affiliate-image-layer-4 {
    width: 60vw;
    height: auto;
    top: -3.1vw;
    position: relative;
  }
  #image_4 {
    top: -2vw;
    width: 54vw;
    height: 59vw;
  }

  #card_4 {
    top: -2vw;
  }

  .affiliate-card-1img-bg {
    z-index: -1;
    position: absolute;
    top: 7.5vw;
    left: 6vw;
    width: 60vw;
    height: 50vw;
    background: rgba(20, 20, 20, 1);
    border: 2px solid transparent;
    border-image: linear-gradient(90deg, #790a83, #00f1ff) 1;
  }

  .card-content-affiliate {
    top: -10vw;
    left: 0;
  }
  #card-content-affiliate_2 {
    top: -0vw;
    left: 0;
  }

  .card-title-layer-4 {
    font-size: 5vw;
    width: 80vw;
    height: 15vw;
    margin-left: -5vw;
  }

  .card-description-layer-4 {
    font-size: 3vw;
    margin-top: -4vw;
    text-align: justify;
    width: 70vw;
    margin-left: 0vw;
  }

  .image-placeholder img {
    width: 80vw;
    height: 50vw;
    /* border: 0.2vw solid white; */
    margin-top: -5vw;
    margin-left: 1vw;
  }

  .visual-box img {
    width: 80vw;
    height: 50vw;
    margin-top: 0vw;
    /* border: 2px solid white; */
    margin-left: -4vw;
  }

  .affiliate-image-layer-3 {
    width: 45vw;
    height: 72vw;
    z-index: 2;
    position: relative;
    /* top: 7.5vw; */
    top: -14vw;
  }

  .bg-content-Affiliate-Program {
    width: 100vw;
    min-height: 100vh;
    margin: 0.5vw auto;
    background-image: url("../../assets/affilited-bg.png");
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-x: hidden;
    margin-bottom: 11vw;
  }

  .business-grid1 {
    width: 11vw;
    text-align: center;
  }

  .Affliate_program_iconsMovement {
    margin-top: -20vw;
  }

  .affiliate_side p {
    font-size: 3vw;
    /* background: linear-gradient(180deg, #790a83 45%, #00f1ff 88%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; */
    color: #fff;
    position: fixed;
    top: 20vw;
    left: 0vw;
    z-index: 100;
    padding: 1vw 2vw;
    border-top-right-radius: 3vw;
    border-bottom-right-radius: 3vw;

    background-color: #790a83;
  }
}
