.animation-hrms-page-container {
  display: none;
}

@media screen and (min-width: 320px) and (max-width: 720px) {
  .animation-hrms-page-container {
    margin-top: 20vw;
    display: block;
    /* border: 1px solid red; */
  }

  .animation-page-first-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
  }
  .animation-top-section {
    /* border: 1px solid #fff; */
  }

  .animation-features-1st-page-title-1 {
    font-size: 5vw;
    width: 80vw;
    text-align: center;
  }
  .animation-features-1st-page-title-2 {
    width: 80vw;
    font-size: 5vw;
    text-align: center;
  }
  .animation-layerOne-center-h {
    width: 80vw;
    font-size: 5vw;
    text-align: center;
  }
  .animation-layer-1-one-p_para-color {
    width: 80vw;
    font-size: 3vw;
    text-align: center;
  }
  .animation-layer-1-two-p_para-color {
    width: 80vw;
    font-size: 3vw;
    text-align: center;
  }
  .animation-layer-1-three-p_para-color {
    text-align: center;
    width: 80vw;
    font-size: 3vw;
  }
  .animation-styled-hr {
    width: 100vw;
    height: 0vw;
    background: #9d72b4a8;
    opacity: 1;

    box-shadow: 0vw -0.63vw 1.61vw 0.21vw #c370f0,
      0vw 0.78vw 1.6vw 0.21vw #c370f0, 0vw -0.78vw 15.62vw 0.21vw #c370f0,
      0vw 0.78vw 31.24vw 0.21vw #c370f0, 0vw -0.78vw 53.44vw 0.21vw #c370f0,
      0vw 0.78vw 93.7vw 0.21vw #c370f0;
      margin: 8vw 0;
  }
  .animation-title-center-feature-page {
    /* border: 1px solid red; */
    margin: 2vw 0vw;
  }

  #animation-globe_1 {
    width: 80vw;
    height: 50vw;
  }
  .image_container {
    width: 80vw;
  }
  .animation-globe_2 {
    width: 100vw;
    height: 60vw;
  }
  .animation-globe_3 {
    width: 100vw;
    height: 60vw;
  }
  .animation-globe_4 {
    width: 100vw;
    height: 60vw;
  }

  .animation-page_second-page {
    /* border: 1px solid rgb(23, 182, 52); */
    margin: 1vw 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
  }
  .animation-middle-section-bottom {
    font-size: 5vw;
    width: 80vw;
    text-align: center;
    margin-top: 1vw 0;
  }
  .animation-bottom-section {
    margin: 1vw 0;
  }
  .animation-layer-2-one-p_para-color {
    font-size: 3vw;
    width: 80vw;
    text-align: center;
  }
  .animation-bottom-section {
    width: 80vw;
    text-align: center;
    /* border: 1px solid yellow; */
  }
  .animation-layer-2-two-p_para-color {
    text-align: center;
    font-size: 3vw;
  }
  .animation-layer-2-three-h {
    text-align: center;
    font-size: 5vw;
  }
  .animation-layer-2-three-p_para-color {
    text-align: center;
    font-size: 3vw;
  }
  .animation-layer-2-one-h {
    font-size: 5vw;
  }

  #animation-globe_2 {
    width: 100vw;
    height: 50vw;
    /* border: 1px solid #fff; */
  }


  /* third  container css*/

  #animation-globe_3{
    width:100vw;
    height: 50vw;
    /* border: 1px solid red; */
  }

  .animation-top-third-section{
    display: flex;
    flex-direction: column;
    width: 100vw;
    justify-content: center;
    align-items: center;
  }
  .animation-title-third-page-feature-page{
    width: 80vw;
    text-align: center;
    margin: 2vw 0;
  }
  .animation-features-third-page-title-1{
    width: 80vw;
    text-align: center;
    margin: 2vw 0;
    font-size: 5vw;
  }
  .animation-features-third-page-title-2{
    width: 80vw;
    text-align: center;
    margin: 2vw 0;
    font-size: 5vw;
  }
  .animation-features-third-page-title-3{
    width: 80vw;
    text-align: center;
    margin: 2vw 0;
    font-size: 5vw;
  }
  .animation-layer3-top-p_para-color{
    width: 80vw;
    text-align: center;
    font-size: 3vw;
  }
  .animation-layer3-top-2-p_para-color{
    width: 80vw;
    text-align: center;
    font-size: 3vw;
  }
  .animation-layer-3-bottom-p_para-color{
    width: 80vw;
    text-align: center;
    font-size: 3vw;
  }

  /* fourth css style  */

  #animation-globe_4{
    width: 100vw;
    height: 50vw;
    /* border: 1px solid #fff; */
  }
  
  .animation-bottom-section{
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }
  .animation-middle-section-bottom{
    width: 80vw;
    /* border: 1px solid red; */
    margin: 2vw 0;
  }
  .animation-layer4-top-1-p{
    font-size: 3vw;
    text-align: center;
    width: 80vw;
  }
  .animation-layer4-top-1-h{
    font-size: 5vw;
    width: 80vw;
    text-align: center;
  }
  .animation-title-bottom{
    width: 80vw;
    text-align: center;
   /* border: 1px solid; */
   margin: 2vw 0;
  }
  .animation-layer4-left-2-h{
    width: 80vw;
    text-align: center;
    font-size: 5vw;
  }
  .animation-layer4-left-2-p{
    width: 80vw;
    text-align: center;
    font-size: 3vw;
  }
  .animation-img-container{
    width: 80vw;
  }
  .animation-title-bottom-second-layer_bottom{
    width: 80vw;
    /* border: 1px solid ; */
    margin: 2vw 0;
  }
  .animation-layer4-rigth-3-h{
    width: 80vw;
    font-size: 5vw;
    text-align: center;
  }
  .animation-layer4-rigth-4-p{
    width: 80vw;
    font-size: 3vw;
    text-align: center;
  }
}
