.carousel-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  color: #fff;
  padding: 0px 20px 20px 20px;
  position: relative;
}

.carousel-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 80vw;
  width: 100vw;
}

.text-container {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 25vw;
}

.description {
  font-size: 1.4vw;
  line-height: 2.7vw;
  margin-bottom: 1.4vw;
}

.quote {
  font-size: 5vw;
  color: #fff;
  position: absolute;
  top: -2vw;
  left: -2vw;
}

.end-quote {
  position: absolute;
  bottom: -30px;
  right: -30px;
}

.user-info {
  display: flex;
  align-items: center;
}
.user-info img {
  width: 10vw;
  height: 7vw;
 
}

.logo {
  width: 6vw;
  height: 6vw;
  margin-right: 1vw;
  border-radius: 50%;
}

.info {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 2vw;
  font-weight: bold;
}

.role {
  font-size: 1.3vw;
  color: #ccc;
  margin-left: 2vw;
}

.carousel-arrows {
  display: flex;
  justify-content: space-between;
  width: 6vw;
  margin-top: 10vw;
}

.arrow {
  font-size: 5vw;
  cursor: pointer;
  user-select: none;
   color: #790a83;

}

/* .arrow:hover {
  color: #f90;
} */

.left {
  margin-right: 1vw;
}

.right {
  margin-left: 1vw;
}
@media screen and (max-width: 799px) {
  .carousel-container {
    width: 80%;
    margin-bottom: 8vw;
    margin-top: 2vw;
  }
  .description {
    font-size: 3vw;
    line-height: 6vw;
    text-align: justify;
    width: 70vw;
    margin-left: 6vw;
    /* word-spacing: -1vw; */
    margin-bottom: 3vw;
    text-align: justify;
  }
  .title {
    font-size: 4vw;
  }
  .role {
    font-size: 3vw;
  }
  .arrow {
    font-size: 8vw;
    /* margin-right: 10vw; */
  }
  .text-container {
    margin-right: 5vw;
  }

  
.carousel-arrows {
  display: flex;
  justify-content: space-between;
  width: 6vw;
  margin-top: 22vw;
  margin-left: -11vw;
}


.user-info img {
  width: 15vw;
  height: 11vw;
 
}

.user-info {
  display: flex;
  align-items: center;
  padding-left: 5vw;
}

.carousel-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 80vw;
  width: 100vw;
  height: 45vw;
}
}
