* {
  text-decoration: none;
  box-sizing: border-box;
}
.nav-bar-bg {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -1vw;
  position: sticky;
  top: 0;
  z-index: 1100;
  background-color: black;
}
.navbar_container {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0vw 1.5vw 0 1.5vw;
  gap: 1vw;
  box-shadow: 0 0 0.5vw 0vw;
}
/* .logo_image {
  height: 5vw;
} */
.logo_image > img {
  width: 15vw;
  height: 5vw;
}
.nav_items {
  display: flex;
  margin-left: 4vw;
  align-items: center;
  justify-content: center;
  /* height: 6vw; */
}
.nav-unorder-list {
  display: flex;
  gap: 2vw;
  align-items: center;
}
.nav-unorder-list .nav_item {
  list-style: none;
  font-size: 1.5vw;
  font-weight: bold;
  background: linear-gradient(180deg, #790a83 45%, #00f1ff 88%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 0.5vw;
  cursor: pointer;
}
.nav-unorder-list .active {
  background-color: #790a83;
  color: #fff;
  border-radius: 3vw;
  font-size: 1.6vw;
  list-style: none;
  padding: 0.2vw 1vw 0.3vw 1vw;
  cursor: pointer;
}

.nav_button {
  /* border: 0.1vw solid black; */
  box-shadow: 0vw 0vw 1vw 0vw #790a83;
  padding-left: 1vw;
  padding-right: 1vw;
  border-radius: 2vw;
  margin-left: 8vw;
  height: 3vw;
  width: 11vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 3vw;
  cursor: pointer;
}
.nav_button button {
  font-size: 1.4vw;
  border: none;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  display: block;
}
.nav_button.show {
  display: block; /* Ensure it's shown in mobile view */
}
.CuroselIcon,
.CrossMark {
  display: none;
}

/* For Mobile View: Display the menu icon */
@media screen and (min-width: 320px) and (max-width: 720px) {
  .mobile_view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; /* Make sure it occupies full width */
    padding: 0 16px; /* Add some padding if needed */
  }
  .logo_image {
    margin-top: 1vw;
  }
  .logo_image > img {
    width: 43vw;
    height: 15vw;
  }
  .CuroselIcon,
  .CrossMark {
    display: block;
    font-size: 5vw;
    margin-top: 1.8vw;
  }

  /* Hide the nav items initially */
  .nav_items {
    position: absolute;
    top: 18vw;
    right: 2vw;
    width: 80vw;
    height: 70vw;
    background-color: #1e1f1fa8;
    backdrop-filter: blur(10px); /* The blur effect */
    -webkit-backdrop-filter: blur(10px); /* Safari support */
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    border-radius: 2vw;
  }
  .nav_items ul {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    /* border: 1px solid #fff; */
    margin-left: 20vw;
  }
  .nav-unorder-list .nav_item {
    font-size: 6vw;
  }
  .nav-unorder-list .active {
    font-size: 6vw;
    border-radius: 5vw;
    padding-left: 3vw;
    padding-right: 3vw;
  }
  /* Show nav items when active */

  .nav_items.show {
    display: flex;
    
  }
  .nav-unorder-list {
    list-style: none;
    padding: 0;
    text-align: center;
  }
  .nav_item {
    padding: 20px;
    font-size: 1.5rem;
    width: 100%;
    text-align: center;
    cursor: pointer;
    transition: background 0.3s;
  }
  .nav_button {
    display: block;
    text-align: center;
    width: 35vw;
    margin-top: 4vw;
    height: 9vw;
    border-radius: 10vw;
    margin-left: 20vw;
  }
  .nav_button button {
    font-size: 5vw;
    margin-left: 2vw;
    margin-top: 2vw;
  }

  .nav-bar-bg {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -1vw;
    position: fixed;
    top: 0;
    z-index: 1100;
    background-color: black;
  }
}
