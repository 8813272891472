.careers_main_h > h1 {
  text-align: center;
  font-size: 2.7vw;
  font-family: "Plus Jakarta Sans", sans-serif;
  position: relative;
  bottom: 1.1vw;
  text-shadow: 0px 0px 10px rgba(195, 112, 240, 1),
    0px 0px 20px rgba(195, 112, 240, 1), 0px 0px 40px rgba(195, 112, 240, 1),
    0px 0px 80px rgba(195, 112, 240, 1);
  margin-top: 5vw;
}
.careers_main {
  border-radius: 20px;
  width: 85vw;
  padding: 1vw 0vw 18vw 0vw;
  margin-left: 2vw;
  margin-top: 2vw;
  position: relative;
  /* z-index: 10; */
}
.careers_main::after {
  content: "";
  position: absolute;
  top: 0vw;
  left: 1.5vw;
  right: 0;
  bottom: 0;
  border-radius: 1vw;
  padding: 0.15vw;
  background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  width: 90vw;
  height: 65vw;
}
.careers_main > h1 {
  margin-left: 6vw;
  margin-top: 4vw;
  font-size: 3vw;
  font-family: inherit;
}
.careers_bg_div {
  background: url("../../assets/image 2900 FEATURES.png");
  width: 100vw;
  height: 60vw;
  background-repeat: no-repeat;
  margin: 1vw;
  background-size: contain;
  background-position: 10vw;
}
.career-section1 {
  display: flex;
  justify-content: space-evenly;
  margin-top: 2vw;
}

.career-section1-content1 {
  margin-left: 3vw;
  width: 20vw;
  height: 30vw;
}

.career-contents > h3 {
  font-family: inherit;
  font-size: 2vw;
  /* font-weight: 600; */
}

.career-contents > p {
  font-family:inherit;
  font-size: 1.3vw;
}

.career-section1 > img {
  width: 25vw;
  height: 30vw;
  margin-top: -3vw;
}

/* section 2 */
.career-section2 {
  display: flex;
  flex-direction: column;
  margin-top: 5vw;
}

.career-section2 > h1 {
  font-family: inherit;
  font-size: 3vw;
  /* font-weight: 600; */
  margin-left: 5vw;
  margin-top: 2vw;
}

.career-section2-main {
  display: flex;
  /* justify-content: space-around; */
  /* align-items: center; */
  margin-top: 1vw;
}

.career-section2-main-container {
  display: flex;
  flex-direction: column;
  /* width */
  width: 45vw;
  margin-left: 1.8vw;
}

.career-section2-content-box > h4 {
  font-size: 1.5vw;
  font-family: "Helvetica Now Display Regular";
  margin-bottom: -0.5vw;
}
.career-section2-content-box {
  width: 18vw;
}
.career-section2-content {
  margin-left: 2.5vw;
  margin-top: 2vw;
}
.career-section2-content-box > p {
  font-size: 1.1vw;
}

.career-section2-content {
  width: 37vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.career-section2-content > button {
  position: relative;
  width: 8vw;
  height: 3vw;
  border-radius: 1vw;
  border: none;
  font-family: "Helvetica Now Display Extra Bold";
  /* z-index: 1; */
  overflow: hidden;
  background-color: black;
}

.career-section2-content > button::after {
  content: "";
  position: absolute;
  top: 0vw;
  left: 0vw;
  right: 0;
  bottom: 0;
  border-radius: 1vw;
  padding: 0.15vw;
  background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  /* width: 20vw; */
  /* height: 5vw; */
}

.career-section2-side {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  width: 45vw;
  position: relative;
}

.career-section2-side > div {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  /* align-items: center; */
  width: 25vw;
}

.career-section2-side h3 {
  width: 18vw;
  font-size: 1.5vw;
  font-family: "Helvetica Now Display Medium";
}

.career-section2-side img {
  cursor: pointer;
  width: 4vw;
  height: 4vw;
  padding: 1vw;
}
.career-section2-side::after {
  content: "";
  position: absolute;
  top: 0.3vw;
  left: 29.6vw;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  padding: 0.15vw;
  background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  width: 3vw;
  height: 3vw;
}
body.modal-open {
  overflow: hidden;
}
body.modal-open1 {
  overflow: hidden;
}
/* Modal background overlay */
.application-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  font-family: "Helvetica Now Display Medium";
}
.application-modal1 {
  position: fixed;
  top: 4vw;
  left: 0;
  width: 100vw;
  height: 90%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  font-family: "Helvetica Now Display Medium";
}
.application-modal1-main {
  display: flex;
  justify-content: space-between;
  margin-top: 2vw;
}
.application-modal1-date {
  margin-top: 1vw;
}
/* Modal content box */
.application-modal-content {
  background-color: rgb(0, 0, 0);
  padding: 20px;
  border-radius: 8px;
  width: 90vw;
  max-width: 70%;
  max-height: 80%;
  overflow-y: auto;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
  font-family: "Helvetica Now Display Medium";
}
.application-modal-content1 {
  background-color: black;
  padding: 0vw 5vw 2vw 5vw;
  width: 90vw;
  max-width: 50%;
  max-height: 90%;
  overflow-y: auto;
  scrollbar-width: none;
  overflow-x: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
  font-family: "Helvetica Now Display Medium";
}
.application-modal-content1 button {
  margin-left: 0vw;
  margin-top: 2vw;
  margin-bottom: 1vw;
  width: 40vw;
  height: 4vw;
  background: linear-gradient(90deg, #790a83 28%, #00f1ff 100%);
  color: white;
  font-size: 2vw;
  font-family: "Helvetica Now Display Medium";
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 1vw;
}
.application-modal-content1 h1 {
  font-size: 1.5vw;
  font-family: "Helvetica Now Display Thin";
}
.application-modal-content1 h2 {
  color: #b9adc2;
}
.application-modal-content1 hr {
  border: 1px solid white;
}
/* Close button at the top right of the modal */
.application-modal-content .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
  color: black;
  font-family: "Helvetica Now Display Extra Bold";
}
.application-modal-content1 .close-button1 {
  position: absolute;
  top: vw;
  right: -17vw;
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
  color: black;
  font-family: "Helvetica Now Display Thin";
}
.application-modal1-heading h1 {
  font-size: 2vw;
  font-family: "Helvetica Now Display Regular";
}
.application-modal1-date p {
  color: #b9adc2;
}
.application-modal-content1 h1 {
  text-align: center;
  margin-top: 2vw;
}
.application-modal-content1 h2 {
  margin-top: 2vw;
}
.application-modal-content1 ul li {
  margin-top: 0.5vw;
}
/* Form fields */
.application-modal-content form {
  display: flex;
  flex-direction: column;
}

.application-modal-content form label {
  margin-top: 10px;
  margin-bottom: 5px;
}

.application-modal-content form input {
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  border: 1px solid rgba(121, 10, 131, 1);
  background-color: black;
}
.application-modal-content form input:focus {
  border: 1px solid rgba(121, 10, 131, 1);
  outline: none;
}
.application-modal-content1::after {
  content: "";
  position: absolute;
  top: 0vw;
  left: 0vw;
  right: 0;
  bottom: 0;
  border-radius: 0.5vw;
  padding: 0.15vw;
  background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  height: 110vw;
}
.application-modal-content form input::after {
  content: "";
  position: absolute;
  top: 0vw;
  left: 0vw;
  right: 0;
  bottom: 0;
  border-radius: 0.5vw;
  padding: 0.15vw;
  background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}
.application-modal-content form button {
  padding: 10px;
  background: linear-gradient(90deg, #790a83 28%, #00f1ff 100%);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  font-family: "Helvetica Now Display Extra Bold";
}
.career-section2-content-box > p {
  font-family: "Helvetica Now Display Regular";
}
.application-modal {
  font-family: "Helvetica Now Display Regular";
}
.careers_page_footer_section {
  margin-top: 5vw;
}
@media screen and (min-width: 320px) and (max-width: 720px) {
  .careers_main_div{
    overflow: hidden;
    margin-top: 20vw;
  }
  .careers_main {
    position: relative;
    padding-bottom: 25vw;
  }
  .careers_main::after {
    content: "";
    position: absolute;
    top: 0vw;
    left: 1.5vw;
    right: 0;
    bottom: 0;
    border-radius: 1vw;
    padding: 0.15vw;
    background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    width: 90vw;
    height: auto;
  }
  .careers_main_h > h1 {
    font-size: 5vw;
  }
  .careers_main > h1 {
    font-size: 5vw;
    text-align: center;
  }
  .career-section1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 35vw;
  }
  .career-section1-content1 {
    width: 70vw;
    margin-left: 10vw;
  }
  .career-contents > h3 {
    font-size: 5vw;
  }
  .career-contents > p {
    font-size: 3vw;
    text-align: justify;
    /* word-spacing: -1.5vw; */
    padding: 0.3vw 0;
  }
  .career-section1 > img {
    width: 60vw;
    height: 80vw;
    margin-left: 10vw;
    margin-top: 0vw;
  }
  .career-section2-main-container {
    margin-left: 10vw;
  }
  .career-section2 > h1 {
    font-size: 5vw;
    text-align: center;
  }
  .career-section2-content-box > h4 {
    font-size: 4.5vw;
    width: 46vw;
  }
  .career-section2-content-box > p {
    font-size: 3vw;
    width: 40vw;
  }
  .career-section2-side {
    position: relative;
    top: 35vw;
  }
  .career-section2-content > button {
    width: 20vw;
    position: relative;
    left: 30vw;
    height: 10vw;
  }

  .career-section2-side h3 {
    font-size: 4vw;
    width: 60vw;
    margin-left: -40vw;
  }
  .career-section2-side img {
    width: 9vw;
    height: 9vw;
  }
  .career-section2-side::after {
    content: "";
    position: absolute;
    top: -0.6vw;
    left: 21.3vw;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    padding: 0.15vw;
    background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    width: 10vw;
    height: 10vw;
  }
  .application-modal1{
    margin-top: 10vw;
  }
  .application-modal-content1{
max-width: 80%;
margin-top: -7vw;
  }
.application-modal-content1 h1 {
  font-size: 5vw;
  margin-top: 0vw;
}
.close-button1{
  font-size: 8vw !important;
  position: relative !important;
  top: -1vw !important;
  margin-left: 5vw !important;
}
.application-modal-content1 h2{
  font-size: 4vw;
}
.application-modal-content1 ul li{
  font-size: 3vw;
}
.application-modal-content1 p{
  font-size: 3vw;
}
.application-modal-content1::after {
  content: "";
  position: absolute;
  top: 0vw;
  left: 0vw;
  right: 0;
  bottom: 0;
  border-radius: 0.5vw;
  padding: 0.15vw;
  background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  height: 225vw;
}
.application-modal1-date p{
  font-size: 3vw;
  margin-top: -2vw;
}
.application-modal-content1 button{
  width: 70vw;
  height: 6vw;
  font-size: 3vw;
}
.application-modal-content .close-button{
  font-size: 7vw !important;
}
.application-modal-content{
  border-radius: 0.5vw;
}
  .careers_page_footer_section {
    margin-top: 192vw;
  }

  .careers_bg_div {
    background: url("../../assets/image 2900 FEATURES.png");
    width: 100vw;
    height: 90vw;
    background-repeat: no-repeat;
    margin: 1vw;
    background-size: contain;
    background-position: 10vw;
  }
  .apply-button{

  }

  .application-modal-content form button {
    padding: 8px;
    background: linear-gradient(90deg, #790a83 28%, #00f1ff 100%);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    font-family: "Helvetica Now Display Extra Bold";
    font-size: 5vw;
  }

  .career-section2-main {
    display: flex;
    /* justify-content: space-around; */
    /* align-items: center; */
    margin-top: 1vw;
    width: 86vw;
    /* border: 1px solid red; */
  }
}
