* {
  color: white;
  padding: 0px;
  margin: 0px;
}
.privacy-policy-main {
  width: 100vw;
  min-height: 100vh;
  margin-bottom: 5vw;
}
/* .privacy_policy_heading_shadow{
  width: 18vw;
  background-color: rgba(195, 112, 240, 0.6);
  height: 1.2vw;
  margin-left: 40vw;
  margin-bottom: 5vw;
  margin-top: 4vw;
  box-shadow: 0vw 0vw 4vw 1vw rgba(195, 112, 240, 1);
} */
.privacy_policy_heading_shadow h1 {
  position: relative;
  left: 1vw;
  bottom: 0.9vw;
  font-size: 2.5vw;
  font-family: "Plus Jakarta Sans", sans-serif;
  text-shadow: 0px 0px 10px rgba(195, 112, 240, 1),
    0px 0px 20px rgba(195, 112, 240, 1), 0px 0px 40px rgba(195, 112, 240, 1),
    0px 0px 80px rgba(195, 112, 240, 1);
  text-align: center;
  top: 5vw;
}
.privacy-policy-bg-div {
  background: url("../../assets/image 2900 FEATURES.png");
  width: 95vw;
  height: 110vw;
  background-repeat: no-repeat;
  margin: 1vw;
  background-size: contain;
  /* background-position: 10vw; */
  padding-right: 1vw;
  position: relative;
  top: 8vw;
 
}
.privacy-policy-content-div {
  margin: 1vw;
  width: 93vw;
  padding: 1vw;
  /* padding-left: 2vw; */
  /* padding-top: 3vw; */
  /* position: relative; */
}
.privacy-policy-content-div::after {
  content: "";
  position: absolute;
  top: 0vw;
  left: 0.5vw;
  right: 0;
  bottom: 0;
  border-radius: 1vw;
  padding: 0.15vw;
  background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  width: 96vw;
  height: 107vw;
  /* border: 1px solid red; */
}
.privacy-policy-content-div p {
  color: #ac9f9f;
  font-size: 1.3vw;
  width: 93vw;
  text-align: justify;
}
.privacy-policy-content-div h1 {
  color: #cbcacb;
  font-size: 2vw;
  margin-top: 1vw;
  font-family: "Roboto";
}
/* .privacy_policy_prefooter{
  margin-top: 5vw;
} */
@media screen and (min-width: 320px) and (max-width: 720px) {
  .privacyPolicy{
    overflow: hidden;
  }
  .privacy_policy_heading_shadow h1 {
    font-size: 5vw;
  }
  .privacy-policy-bg-div {
    width: 100%;
    height: 100%;
  }
  .privacy-policy-content-div {
    position: relative;
    top: -2vw;
    padding: 2vw;
  }
  .privacy-policy-content-div p{
    font-size: 3vw;
    padding: 1vw 2vw;

  }
  .privacy-policy-content-div h1{
    font-size: 5vw;
    padding: 0 2vw;
  }
  .privacy-policy-content-div::after{
    content: "";
    position: absolute;
    top: 2vw;
    left: 0vw;
    right: 0;
    bottom: 0;
    border-radius: 1vw;
    padding: 0.15vw;
    background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    width: 96vw;
    height: auto;
  }

  .privacy-policy-main {
    width: 100vw;
    min-height: 100vh;
    margin-bottom: 5vw;
    margin-top: 20vw;
  }
}
