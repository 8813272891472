.icons-hr-line-top {
  width: 100%;
  width: 100vw;
  border: none; /* Remove the default border */
  height: 0.1vw; /* Set the thickness of the line */
  background-color: rgba(
    121,
    10,
    131,
    1
  ); /* Set the background color (color of the line) */
}
.icons-hr-line-btm {
  width: 100%;
  width: 100vw;
  border: none; /* Remove the default border */
  height: 0.1vw; /* Set the thickness of the line */
  background-color: rgba(
    121,
    10,
    131,
    1
  ); /* Set the background color (color of the line) */
}
/* icons-scrolling css code */
.clients {
  width: 100vw;
  display: flex;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 1vw;
  margin-bottom: 1vw;
}

.Client-Cards-MMM {
  display: flex;
  position: relative;
  animation-play-state: running;
  animation: ClientScrolling 70s linear infinite;
}

@keyframes ClientScrolling {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

.client-section-container {
  display: flex;
  flex-direction: column;
}

.client-container-box {
  width: 20vw;
  height: 10vw;
  display: flex;
  flex-direction: column;
  border-radius: 1vw;
  justify-content: center;
  align-items: center;
}

.client-container-box p {
  text-align: justify;
}

.claint-images-1 {
  width: 11vw;
  height: 5vw;
 
}

#clint_image_3{
  width:7vw ;
  height: 5vw;
  /* border-radius: 50%; */
}
#clint_image_1 {
  width: 7vw;
  height: 7vw;
  border-radius: 50%;
  /* border-radius: 14vw; */
}

#clint_image_2 {
  width: 13vw;
  height: 5vw;
}
#clint_image_3 {
  width: 13vw;
  height: 7vw;
}
#clint_image_4 {
  width: 15vw;
  height: 7vw;
}
#clint_image_0 {
  width: 12vw;
  height: 4vw;
}

@media screen and (min-width:320px) and (max-width:720px) {
  .client-container-box {
    margin: 0 3vw;
  }
  
  #clint_image_1 {
    width: 15vw;
    height: 10vw;
    border-radius: 50%;
    /* border-radius: 14vw; */
  }
  
  #clint_image_2 {
    width: 19vw;
    height: 8vw;
  }
  #clint_image_3 {
    width: 20vw;
    height: 10vw;
  }
  #clint_image_4 {
    width: 22vw;
    height: 10vw;
  }
  #clint_image_0 {
    width: 20vw;
    height: 10vw;
  }
  
}
