.main_container {
  /* min-height: 100vh; */
  color: white;
  background-color: black;
  overflow-x: hidden;
  min-height: 100vh;
  width: 100vw;
}

.layer1 {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.border-text {
  font-size: 20vw;
  font-weight: bold;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
  position: relative;
}

.home-layer-p {
  margin-top: -4vw;
  font-size: 3.4vw;
  font-weight: 100;
  background: linear-gradient(0deg, #ffffff 24.53%, rgba(0, 0, 0, 0) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}
.layer2 {
  height: 100vh;
  width: 100vw;
  margin-top: 5vw;
  background-color: black;
  margin-top: 5vw;
}

.img1-home-layer-1 {
  width: 100vw;
  height: 15vw;
}
/* .layer2_img2 {
  margin-top: 5vw;
} */
.layer-2-home-img {
  width: 100vw;
  height: 17.5vw;
  margin-bottom: 0vw;
}

.layer3-home {
  display: flex;
  margin-top: 5vw;
  background-color: black;
  padding-left: 7vw;
  padding-right: 7vw;

  height: 25vw !important;
}

.layer3_sub1 {
  display: flex;
}
.layer3_img {
  position: relative;
  bottom: 3vw;
  width: 60vw;
  height: 30vw;
}
.layer3_img img {
  width: 30vw;
  height: 28vw;
  margin-top: -1vw;
}
.layer3_img_img1 {
  width: 30vw;
  height: 23vw;
  position: relative;
}
.layer3_img_img2 {
  border: 1px solid white;
  width: 65vw;
  height: 18vw;
  position: absolute;
}
.layer3_content {
  margin-left: -23vw;
  width: 50vw;
  text-align: justify;
  margin-top: -1vw;
}
.layer3_content span {
  font-size: 2.3vw;
  color: #cbcbcb;
}
.layer3_content p {
  font-size: 1.4vw;
  background: rgba(148, 148, 148, 1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 45vw;
}
.layer4 {
  /* margin-top: 20vw; */
  display: flex;
  margin-top: -23vw;
  background-color: black;
  /* padding-bottom: 10vw; */
  padding-left: 7vw;
  padding-right: 7vw;
  /* padding-bottom: 15vw; */
  /* border: 1px solid red; */
}
.layer4_sub1 {
  display: flex;
}
.layer4_img {
  position: relative;
  margin-top: 18vw;
  margin-left: -5vw;
}

.layer4_img img {
  width: 30vw;
  height: 33vw;
  margin-top: -1vw;
}
.layer4_img_img1 {
  width: 30vw;
  height: 23vw;
  position: relative;
}
.layer4_img_img2 {
  border: 1px solid white;
  width: 60.05vw;
  height: 18vw;
  margin-left: -30vw;
  position: absolute;
}
.layer4_content {
  margin-top: 20vw;
  margin-left: 0vw;
  margin-right: 7vw;
  width: 50vw;
  text-align: justify;
}
.layer4_content span {
  font-size: 2.3vw;
}
.layer4_content p {
  font-size: 1.4vw;
  width: 45vw;
  background: rgba(148, 148, 148, 1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#partner_container{
  margin-top: -5vw;
}

.layer6-bg {
  background-image: url("../../assets/image\ 2900.png");
  height: 40vw;
  background-repeat: no-repeat;
}
.layer6 {
  display: flex;
  padding-right: 5vw;
  text-align: justify;
  gap: 5vw;
  padding-bottom: 3vw;
  position: relative;
  bottom: 45vw;
}
.layer6_content1 {
  width: 45vw;
  height: 13vw;
  border: 1px solid black;
  box-shadow: 0px 0px 16px 6px rgba(120, 69, 148, 1),
    0px 0px 41px 0px rgba(120, 69, 148, 1);
  padding: 2vw 3vw 2vw 3vw;
  margin-left: 5vw;
  color: #fff;
  transition: height 1s ease-in-out;
  overflow: hidden;
  position: relative;
  z-index: 1000;
}
.layer6_content1:hover {
  height: 12vw;
}
.hidden_image1 {
  margin-top: 3vw;
  position: relative;
  z-index: 10000;
}
.layer6_content1:hover .hidden_image1 {
  display: block;
}
.layer6_content1 h1 {
  font-size: 1.4vw;
  font-weight: 700;
  line-height: 48.41px;
  margin-bottom: 12px;
  background: rgba(255, 255, 255, 1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.layer6_content1 p {
  font-size: 1.1vw;
  padding-bottom: 2vw;
  position: relative;
  z-index: 100;
  background: rgba(255, 255, 255, 1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.layer6_content2 {
  width: 45vw;
  height: 15vw;
  border: 1px solid black;
  box-shadow: 0px 0px 14px 0px #790a83;
  padding: 2vw 3vw 2vw 3vw;
  margin-left: 5vw;
  color: #fff;
  transition: height 0.8s ease-in-out;
  overflow: hidden;
}
.layer6_content2:hover {
  height: 40vw;
}
.hidden_image2 {
  margin-top: 3vw;
}
.layer6_content2:hover .hidden_image2 {
  display: block;
}
.vector_image {
  position: relative;
  bottom: 1.2vw;
  z-index: 0;
  margin-bottom: 6vw;
  top: 23vw;
  width: 100vw;
}
.layer6_content2 h1 {
  font-size: 2.5vw;
  font-size: 40px;
  font-weight: 700;
  line-height: 48.41px;
  margin-bottom: 12px;
}
.layer6_content2 p {
  font-size: 1.2vw;
}

/* carousel css related code */
.layer6_content1.hovered {
  height: 25vw;
}
.layer5 {
  height: 65vh;
  margin-top: 7vw;
}
.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-block-size: 64vh;
  color: white;
  background-color: black;
}

.client-description {
  display: flex;
  justify-content: center;
  padding: 1.4vw;
  border-radius: 0.4vw;
  background: #000000;
  color: white;
  width: 60vw;
  min-height: 16vw;
  border-radius: 0.6vw;
  box-shadow: 0px 0px 16px 6px rgba(120, 69, 148, 1),
    0px 0px 41px 0px rgba(120, 69, 148, 1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 2;
  position: relative;
}

.polygon-img {
  position: relative;
  top: -2.5vw;
  width: 15vw;
  height: 12vw;
  /* z-index:; */
  border-top: none;
  /* background-color: red; */
  filter: none;
  /* display: none; */
}
.cover_container{
  position: absolute;
  width: 10vw;
  height: 10.5vw;
  background-color: #000000;
  z-index: 1000;
  top: 15.8vw;
  /* clip-path: polygon(50% 0%, 100% 0, 86% 38%, 14% 38%, 0 0); */
  clip-path: polygon(50% 0%, 100% 0, 51% 68%, 27% 36%, 0 0);


}

/* .polygon-img::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0; 
  width: 15vw;
  height: 12vw;
  z-index: 1000;
  background-color: red;
} */
.client-img-large {
  border-radius: 50%;
  width: 10vw;
  height: 10vw;
  margin-right: 2vw;
  margin-left: 0.5vw;
}

.description-text h2 {
  margin: 0;
  font-size: 2vw;
  background: rgba(255, 255, 255, 1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.description-text p {
  font-size: 1.5vw;
  background: rgba(255, 255, 255, 1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.client-carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 7vw;
  margin-top: 0vw;
  margin-left: 5vw;
}

.carousel-image {
  border-radius: 50%;
  overflow: hidden;
  width: 5vw;
  height: 5vw;
  opacity: 0.6;
  transition: all 0.5s ease-in-out;
  /* border: 1px solid red; */
}

.carousel-image img {
  width: 70%;
  height: 70%;
  object-fit: cover;
  margin-top: 1vw;
  border-radius: 50%;
}

/* Active image in the center */
.carousel-image.active {
  width: 13vw;
  height: 13vw;
  opacity: 1;
  transform: scale(1.3); /* Enlarging active image */
}

/* Left images */
.carousel-image.left {
  transform: translateX(-50px); /* Move left-side images left */
}

/* Right images */
.carousel-image.right {
  transform: translateX(50px); /* Move right-side images right */
}

.layer-1-home-text {
  margin-bottom: 4.5vw;
}
.layer-1-home-text img {
  width: 70vw;
  height: 40vw;
  /* margin-top: 6vw; */
}
/* horizontalScroll code */
#horizontal {
  /* border: 1px solid skyblue; */
  display: flex;
  height: 30vh;
  overflow-x: hidden;
  margin-bottom: 1vw;
}
#horizontal .content {
  width: 100vw;
  display: flex;
  justify-content: flex-start;
  flex-shrink: 0;
}
.row {
  display: flex;
  justify-content: center;
  gap: 4vw;
  align-items: center;
  margin-left: 10vvw;
  margin-top: 2vw;
  margin-bottom: 0.5vw;
}

.col {
  display: flex;
  flex-direction: column;
  margin-left: 15vw;
}
.col_1 {
  display: flex;
  flex-direction: column;
  margin-left: 5vw;
}
.col_2 {
  display: flex;
  flex-direction: column;
  margin-left: 7vw;
}
.col_3 {
  display: flex;
  flex-direction: column;
  margin-left: 7vw;
}
.col_4 {
  display: flex;
  flex-direction: column;
  margin-left: 7vw;
}
.row p {
  margin-top: 1.5vw;
  font-size: 1.8vw;
  width: 25vw;
  color: rgba(255, 255, 255, 0.67);
  margin-top: -1vw;
}
/* #payroll{
  margin-top: -1vw;
}
#employee{
  margin-top: -1vw;
}
#leave{
  margin-top: -1vw;
} */
.col_1 h1 , .col_2 h1 , .col_3 h1 , .col_4 h1  {
  font-size: 4vw;
  color: rgba(255, 255, 255, 1);
  font-weight: 600;
  text-transform: capitalize;
  margin-left: -2vw;
}
.row-inside-row-home {
  display: flex;
  align-items: center;
  gap: 0.4vw;
}
.arrow-img-home {
  width: 2vw;
  height: 2vw;
}
.features-hr-line {
  width: 98%;
  margin-top: 1vw;
  color: rgba(255, 255, 255, 1);
}
.arrow-react-icon {
  font-size: 2.4vw;
}
.arrow-react-icon_1 {
  font-size: 2.4vw;
}
.home_icon_movement_div {
  margin-top: 0vw;
}
.layer3_sub1 {
  height: 34vh;
}

.Home-button {
  padding: 0.6vw 1.4vw;
  font-size: 1.5vw;
  color: white;
  background: linear-gradient(90deg, #790a83 28%, #00f1ff 100%);
  border: none;
  border-radius: 0.8vw;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
  margin-top: 2vw;
  /* margin-left: 42vw; */
}

.home-border-affiliate {
  position: relative;
  top: -3.5vw;
  left: 39.3vw;
  width: 16vw;
  border-radius: 0.8vw;
  height: 3vw;
  border: 0.2vw solid white;
}
/* .Become_button{
  display: flex;
  justify-content: center;
  align-items: center;
} */

.home_affilate_conatiner {
  padding: 2vw  8vw 0.6vw 8vw;
}

.become_div {
  height: 5vw;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4vw;
}
#become_div {
  height: 5vw;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1vw;
}


.layer_container_3{
  margin-top: 6vw;
}


@media screen and (min-width: 320px) and (max-width: 720px) {
  .layer1 {
    height: 30vh;
    margin-bottom: 0vw;
    margin-top: 7vw;
  }
  .border-text {
    font-size: 25vw;
  }
  .img1-home-layer-1 {
    height: 20vh;
    width: 100vw;
  }

  .row {
    display: flex;
    justify-content: center;
    gap: 4vw;
    align-items: start;
    margin-left: 10vvw;
    margin-top: 2vw;
    margin-bottom: 0.5vw;
    width: 80vw;
    flex-direction: column;

  }

  .row-inside-row-home {
    display: flex;
    align-items: center;
    gap: 0.4vw;
    width: 80vw;
  }
  .layer2 {
    margin-top: 5vw;
    /* border: 1px solid red; */
    height: 60vh;
    width: 100vw;
    margin-top: 5vw;
    background-color: black;
    margin-top: 5vw;
    z-index: 100;
  }
  .pin-spacer {
    height: 650px !important;
  }
  .horizontal {
    text-align: center;
  }
  .row {
    margin-left: -5vw;
  }
  .col h1 {
    font-size: 5vw;
  }
  .row p {
    font-size: 3vw;
    width: 80vw;
  }
  .arrow-react-icon {
    font-size: 4vw;
    position: relative;
    bottom: 0vw;
  }
  #arrow_icon{
    bottom: 3vw;
  }
  .arrow-react-icon_1 {
    font-size: 4vw;
  }
  .features-hr-line {
    width: 85%;
    margin-left: -3vw;
    margin-top: 2vw;
  }
  .layer-2-home-img {
    width: 100vw;
    height: 20vh;
    margin-top: -5vw;
  }
  .layer3-home {
    margin-top: -30vw;
    margin-left: -8vw;
  }
  .layer3_sub1 {
    display: flex;
    flex-direction: column;
    width: 90vw;
    /* border: 1px solid red; */
    height: 90vh;
    z-index: 1;
    margin-left: 5vw;
  }

  .layer-1-home-text img {
    width: 80vw;
    height: 45vw;
    margin-top: 0vw;
  }

  .layer3_img img {
    width: 80vw;
    height: 80vw;
    margin-top: -10vw;
    margin-left: 6vw;
  }

  .layer3_content {
    margin-left: -23vw;
    width: 50vw;
    text-align: justify;
    margin-top: vw;
    /* border: 1px solid red; */
  }
  .layer3_content span {
    font-size: 5vw;
  }
  .layer3_content p {
    margin-top: 27vw;
    margin-left: 29vw;
    width: 80vw;
    font-size: 3vw;
    text-align: justify;
    /* word-spacing: -2vw; */
  }
  .layer4 {
    display: flex;
    flex-direction: column-reverse;
    width: 100vw;
    height: 97vw;
    margin-left: 0vw;
    display: flex;
    margin-top: 86vw;
    padding-left: 7vw;
    padding-right: 7vw;
    word-spacing: -2vw;
  }

  .layer4_img {
    position: relative;
    margin-top: 0vw;
    margin-left: 0vw;
    height: 54vw;
  }

  .layer4_img img {
    width: 80vw;
    height: 99vw;
    margin-top: -15vw;
    margin-left: 3vw;
  }

  .layer4_content {
    margin-top: 0vw;
    margin-left: 3vw;
    margin-right: 12vw;
    width: 50vw;
    text-align: justify;
    /* border: 1px solid green; */
  }

  .layer4_content span {
    font-size: 5vw;
  }
  .layer4_content p {
    margin-top: 2vw;
    margin-left: 0vw;
    width: 80vw;
    font-size: 3vw;
    text-align: justify;
    /* height: 20vw; */
    /* border: 1px solid green; */
  }
  .layer5 {
    margin-top: -28vw;
  }
  .client-description {
    width: 80vw;
    height: 50vw;
    /* padding-bottom: 7vw; */
    padding: 5vw 0 0 0 ;
  }
  .cover_container{
    position: absolute;
    width: 20vw;
    height: 23vw;
    background-color: #000000;
    z-index: 1000;
    top: 49.5vw;
    /* clip-path: polygon(50% 0%, 100% 0, 86% 38%, 14% 38%, 0 0); */
    clip-path: polygon(50% 0%, 100% 0, 51% 68%, 27% 36%, 0 0);
  
  
  }
  .client-img-large {
    width: 15vw;
    height: 15vw;
  }
  .description-text h2 {
    font-size: 5vw;
  }
  .description-text p {
    font-size: 3vw;
    text-align: justify;
    /* word-spacing: -2vw; */
    margin-right: 2vw;
    width: 58vw;
  }
  .polygon-img {
    position: relative;
    top: -5vw;
    width: 30vw;
    height: 25vw;
  }
  .carousel-image {
    border-radius: 50%;
    overflow: hidden;
    width: 15vw;
    height: 15vw;
    opacity: 0.6;
    transition: all 0.5s ease-in-out;
  }

  .carousel-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 60%;
  }

  /* Active image in the center */
  .carousel-image.active {
    width: 20vw;
    height: 20vw;
    opacity: 1;
    transform: scale(1.3); /* Enlarging active image */
  }

  /* Left images */
  .carousel-image.left {
    transform: translateX(-4vw); /* Move left-side images left */
  }

  /* Right images */
  .carousel-image.right {
    transform: translateX(4vw); /* Move right-side images right */
  }
  .layer6-bg {
    background-image: none;
  }
  .layer6 {
    display: flex;
    flex-direction: column;
    margin-top: 47vw;
  }
  .layer6_content1 {
    width: 80vw;
    height: 39vw;
    margin-left: 10vw;
  }
  .layer6_content1:hover {
    height: 70vw;
  }
  .layer6_content1:hover .hidden_image1 {
    display: block;
  }
  .layer6_content1 h1 {
    font-size: 5vw;
    text-align: center;
    line-height: 6vw;
  }
  .layer6_content1 p {
    font-size: 3vw;
  }
  .home_icon_movement_div {
    margin-top: 32vw;
  }
  .vector_image {
    display: none;
  }
  .client-carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 7vw;
    margin-top: 0vw;
    margin-left: 1vw;
  }

  .Home-button {
    padding: 1.6vw 2.4vw;
    font-size: 3vw;
    color: white;
    background: linear-gradient(90deg, #790a83 28%, #00f1ff 100%);
    border: none;
    border-radius: 0.8vw;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
    margin-top: 2vw;
    /* margin-left: 30vw; */
  }

  .home_affilate_conatiner {
    padding: 5vw 4vw 0vw 4vw;
    margin-left: 7vw;
  }

  .become_div {
    height: 20vw;
    width: 100vw;
  }

  #become_div {
    height: 13vw;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1vw;
  }

  .app-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-block-size: 67vh;
    color: white;
    background-color: black;
    margin-top: 6vw;
  }

  .main_container {
    /* min-height: 100vh; */
    color: white;
    background-color: black;
    overflow-x: hidden;
    min-height: 100vh;
    width: 100vw;
    margin-top: 15vw;
  }

  #partner_container{
    margin-top: 4vw;
  }

  #row_3 , #row_2 , #row_4{
    margin-top: 2vw;
  }
   #row_inside_div_2 {
    margin-left: 12vw;
   } 
   #row_inside_div_3{
    margin-left: 20vw;
  }
   #row_inside_div_5 {
    margin-left: 8vw;
   } 
   #row_inside_div_6{
    margin-left: 30vw;
  }
   #row_inside_div_8 {
    margin-left: 10vw;
   } 
   #row_inside_div_9{
    margin-left: 27vw;
  }
   #row_inside_div_11 {
    margin-left: 10vw;
   } 
   #row_inside_div_12{
    margin-left: 30vw;
  }

  .layer_container_3{
    margin-top: 60vw;
  }
  
}
