.fan_main_container{
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  height: auto;
}

.top_container{
    width: 80vw;
    height: auto;
    /* border: 1px solid red;   */
    display:  flex;
    justify-content: space-between;

}
.work_left_container >h2 ,.work_right_container>h2 ,.bottom_container>h2{
    font-size: 1.5vw;
}
.work_left_container , .work_right_container ,.bottom_container {
    width: 20vw;
    height: auto;
   box-shadow: 0 0 0.1vw 0.1vw;
   border-radius: 1vw;
   padding: 0.5vw 2vw;
}


.work_left_container> p , .work_right_container>p ,.bottom_container >p{
    font-size: 1vw;
    margin-top: 0vw;
    text-align: justify;
}


.work_left_container > img , .work_right_container > img ,.bottom_container > img {
    width: 16vw;
    height: 6vw;
}

.medial_container{
    width: 80vw;
    height: auto;
    /* border: 1px solid green; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1vw;
}

.cricle_1{
    width: 6vw;
    height: 6vw;
    border-radius: 50%;
    border: 1px solid #790a83;
    position: relative;
    


}

.cricle_1 ::after{
    content: " ";
    width: 0vw;
    height: 0.8vw;
    background-color: #790a83;
    border: 1px dotted  #790a83;
    position: absolute;
    top: -1vw;
    left: 3vw;
}
.cricle_1 > p{
    width: 4vw;
    height: 4vw;
    border-radius: 50%;
    border: 1px solid #790a83;
    margin-left: 0.9vw;

}
.cricle_2{
    width: 6vw;
    height: 6vw;
    border-radius: 50%;
    border: 1px solid #790a83;
    position: relative;
    
}
.cricle_2 ::after{
    content: " ";
    width: 0vw;
    height: 0.8vw;
    /* background-color: red; */
    border: 1px dotted  #790a83;
    position: absolute;
    bottom: -1vw;
    left: 3vw;
}
.cricle_2 > p{
    width: 4vw;
    height: 4vw;
    border-radius: 50%;
    border: 1px solid #790a83;
    margin-left: 0.9vw;
}

.cricle_3{
    width: 6vw;
    height: 6vw;
    border-radius: 50%;
    border: 1px solid #790a83;
    position: relative;
}
.cricle_3 ::after{
    content: " ";
    width: 0vw;
    height: 0.8vw;
    /* background-color: red; */
    border: 1px dotted  #790a83;
    position: absolute;
    top: -1vw;
    left: 3vw;
}

.cricle_3 > p{
    width: 4vw;
    height: 4vw;
    border-radius: 50%;
    border: 1px solid #790a83;
    margin-left: 0.9vw;
}


.line_1{
    width: 22vw;
    height: 2vw;
    /* border: 1px solid blue; */
    background-color:#790a83;
}
.doted_lines, .doted_lines_2{
    height: 0.1vw;
    width: 100%;
    border: none;
    background: repeating-linear-gradient(
    to right,
    #fff 0,
    #fff 0.2vw,
    transparent 2px,
    transparent 4px
  );
}

.line_2{
    width: 22vw;
    height: 2vw;
    /* border: 1px solid blue; */
    background-color:#790a83;
}

.last_container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80vw;
    /* border: 1px solid red; */
}

.icons_1 , .icons_3{
    font-size: 2.7vw ;
    margin-top: 0.5vw;
    margin-left: 0.7vw;
}
 .icons_2{
    font-size: 2.3vw ;
    margin-top: 0.6vw;
    margin-left: 0.7vw;
}



@media screen and (min-width: 320px) and (max-width: 720px) {

.fan_main_container{
    display: flex;
    flex-direction: row;
    width: 100vw;
    /* border: 1px solid green; */
    align-items: center;
    height: 120vw;
    justify-content: center;
}
.top_container{
    flex-direction: column;
    width: auto;
    height: 132vw;
    /* border: 1px solid red; */
    justify-content: space-around;
   
}
.medial_container{
    flex-direction: column;
    width: 10vw;
    height: 132vw;
    /* border: 1px solid red; */
}
.last_container{
    width: auto;
}


.work_left_container , .work_right_container ,.bottom_container {
    width: 43vw;
    height: auto;
   padding: 0.5vw 2vw;
}

.work_left_container >h2 ,.work_right_container>h2 ,.bottom_container>h2{
    font-size: 3vw;
}


.work_left_container > img , .work_right_container > img ,.bottom_container > img {
    width: 39vw;
    height: 20vw;
    margin: 1vw 0vw;
}

.work_left_container> p , .work_right_container>p ,.bottom_container >p{
    font-size: 3vw;
    margin-top: 0vw;
    text-align: justify;
    word-spacing: 0vw;

}



.cricle_1{
    width: 8vw;
    height: 8vw;
    border-radius: 50%;
    border: 1px solid #790a83;
    position: relative;
    


}

.cricle_1 ::after{
    content: " ";
    width: 0.8vw;
    height: 0vw;
    background-color: #790a83;
    border: 1px dotted  #790a83;
    position: absolute;
    top: 3.5vw;
    left: -1vw;
}
.cricle_1 > p{
    width: 6vw;
    height: 6vw;
    border-radius: 50%;
    border: 1px solid #790a83;
    margin-left: 0.8vw;
    margin-top: 0.8vw;

}
.cricle_2{
    width: 8vw;
    height: 8vw;
    border-radius: 50%;
    border: 1px solid #790a83;
    position: relative;
    margin: 0;
    
}
.cricle_2 ::after{
    content: " ";
    width: 0.4vw;
    height: 0vw;
    bottom: 3vw;
    left: 8vw;
}
.cricle_2 > p{
    width: 6vw;
    height: 6vw;
    border-radius: 50%;
    border: 1px solid #790a83;
    margin-left: 0.8vw;
    margin-top: 0.8vw;
}

.cricle_3{
    width: 8vw;
    height: 8vw;
    border-radius: 50%;
    border: 1px solid #790a83;
    position: relative;
    margin: 0;
}
.cricle_3 ::after{
    content: " ";
    width: 0.5vw;
    height: 0vw;
    /* background-color: red; */
    border: 1px dotted  #790a83;
    position: absolute;
    top: 3.5vw;
    left: -1vw;
}

.cricle_3 > p{
    width: 6vw;
    height: 6vw;
    border-radius: 50%;
    border: 1px solid #790a83;
    margin-left: 0.8vw;
    margin-top: 0.8vw;
}


.line_1{
    width: 2vw;
    height: 24vw;
    /* border: 1px solid blue; */
    background-color:#790a83;
    margin: 0;
}
.doted_lines, .doted_lines_2{
    height: 24vw;
    width: 2px;
    border: none;
    margin: 0 0.7vw;
    background: repeating-linear-gradient(
    to bottom,
    #fff 0,
    #fff 0.2vw,
    transparent 2px,
    transparent 4px
    
  );
}

.line_2{
    width: 2vw;
    height: 24vw;
    /* border: 1px solid blue; */
    background-color:#790a83;
    margin: 0;
}


.icons_1 {
    font-size: 4vw ;
    margin-top: 1vw;
    margin-left: 0.7vw;
}
 .icons_2{
    font-size: 3.5vw ;
    margin-top: 1vw;
    margin-left: 1vw;
}

 .icons_3{
    font-size: 4.5vw ;
    margin-top: 0.3vw;
    margin-left: 0.7vw;
}


}