* {
  padding: 0px;
  margin: 0px;
}
.clientele-main {
  width: 100vw;
  background-color: black;
  color: #fff;
  padding-top: 3vw;
}
/* .clientele_main_h_shadow{
  width: 15vw;
  background-color: rgba(195, 112, 240, 0.6);
  height: 1.2vw;
  margin-left: 44vw;
  margin-bottom: 5vw;
  margin-top: 4vw;
  box-shadow: 0vw 0vw 4vw 1vw rgba(195, 112, 240, 1);

} */
.Clientele-main-h {
  text-align: center;
  padding: 1vw 0px;
  font-size: 3.2vw;
  font-weight: 700;
  position: relative;
  /* bottom: 3vw; */
  text-shadow: 0px 0px 10px rgba(195, 112, 240, 1),
    0px 0px 20px rgba(195, 112, 240, 1), 0px 0px 40px rgba(195, 112, 240, 1),
    0px 0px 80px rgba(195, 112, 240, 1);
}
.Clientele-content-div {
  margin: -3vw 5vw 0vw 5vw;
}
.Clientele-content-div h1 {
  font-size: 2.5vw;
  font-weight: 600;
  margin-top: -3.5vw;
}
.leftimg {
  margin-right: 1vw;
}
.Clientele-second-h {
  margin-left: 4vw;
}

/* layer-3 css code  */
.layer-3-clientele {
  width: 100vw;
  margin-bottom: 3vw;
}
/* General page styles */
.main-container-clientele {
  background-color: black;
  color: white;
  font-family: Arial, sans-serif;
  margin-bottom: 5vw;
}
.third-img-clientele {
  width: 9vw;
  height: 9vw;
}
/* Trusted By Section */
.trusted-section-clientele {
  text-align: center;
  margin-bottom: 3vw;
}

.trusted-heading-clientele {
  font-size: 2vw;
  padding: 1vw 2vw;
  color: rgba(187, 187, 187, 1);
  background-color: black;
  border: 1px solid rgba(0, 0, 0, 1);
  box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 1),
    0px 0px 12px 0px rgba(255, 255, 255, 1),
    0px 0px 14px 0px rgba(255, 255, 255, 1);
  border-radius: 30px;
  display: inline-block;
  position: relative;
  bottom: -2vw;
  z-index: 11;
}
.arrowfullImg {
  width: 14vw;
}
.logo-grid-clientele {
  width: 100vw;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  z-index: -1;
}

.logo-item-clientele {
  padding: 1vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vw;
  color: #fff;
  font-size: 1vw;
  font-weight: bold;
  border: 1px solid rgba(121, 10, 131, 1);
  z-index: 1;
}
.left-no-border {
  border-left: none;
}
.right-no-border {
  border-right: none;
}
.IconTwo {
  width: 8vw;
  height: 6vw;
border-radius: 50%;
}
.IconFive {
  width: 19vw;
  height: 10vw;
}
.Icontext {
  width: 20vw;
  height: 8vw;
}
.IconSnake {
  width: 10vw;
  height: 8vw;
}
.goodtobuyIcon {
  width: 20vw;
  height: 7vw;
}
.IocnOne {
  width: 23vw;
  height: 8vw;
}
.esIcon-img {
  width: 8vw;
  height: 8vw;
}
/* layer-4-clientele */
.layer-4-clientele {
  margin-top: 6vw;
  padding: 10px 2vw;
  display: flex;
  align-items: center;
  margin-left: 4vw;
}
.layer-4-heading {
  font-size: 5vw;
  width: 50vw;
  margin-left: 2vw;
  color: rgba(255, 255, 255, 1);
}
.layer-4-clientele-first-img {
  width: 20vw;
}
.clientele-book-btn {
  width: 17vw;
  height: 17vw;
  background-color: black;
  border-radius: 50%;
  outline: none;
  border: none;
  box-sizing: border-box;
  font-size: 2.5vw;
  box-shadow: 0px 0px 11px 0px rgba(255, 255, 255, 1);
  transition: all 1s;
  cursor: pointer;
}
.clientele-book-btn:hover {
  width: 22vw;
  height: 22vw;
  font-size: 3vw;
}
.last-layer-clientele {
  display: flex;
  align-items: center;
  background-color: black;
  justify-content: center;
}
.lastContainer-hr-line-left {
  width: 45vw;
  border: 1px solid rgba(121, 10, 131, 1);
  margin-right: 1vw;
}
.lastContainer-hr-line-right {
  width: 4vw;
  margin-left: 1vw;
  border: 1px solid rgba(121, 10, 131, 1);
}
.text-clientele {
  font-size: 2vw;
  color: rgba(187, 187, 187, 1);
}

/* code responsive */
/* Base styles (for larger screens) */
.clientele-main {
  width: 100vw;
  background-color: black;
  color: #fff;
}
.Clientele-main-h {
  text-align: center;
  padding: 1vw 0px;
  font-size: 3.2vw;
  font-weight: 700;
}

/* .Clientele-content-div h1 {
  font-size: 3vw;
  font-weight: 600;
} */
.Clientele-second-h {
  margin-left: 4vw;
}
.third-img-clientele {
  margin-left: 4vw;
  margin-top: 0vw;
}

/* Media query for devices with max-width 799px */
@media only screen and (max-width: 799px) {
  /* Adjust text sizes */
  .Clientele-main-h {
    font-size: 7vw;
  }
  .Clientele-second-h {
    font-size: 6vw;
    margin-left: 2vw;
  }
  .Clientele-content-div {
    margin: 0vw 2vw 2vw 2vw;
  }
  .Clientele-content-div h1 {
    font-size: 5vw;
    margin: -7vw 0 0 7vw ;
  }

  /* Adjust images */
  .third-img-clientele {
    width: 38vw;
    height: 38vw;
    margin-top: 7vw;
  }
  .arrowfullImg {
    width: 60vw;
    margin-left: -7vw;
  }

  /* Adjust grid layout for logos */
  .logo-grid-clientele {
    grid-template-columns: repeat(2, 1fr); /* Two logos per row */
  }

  /* Adjust logo sizes */
  .logo-item-clientele {
    height: 20vw;
  }
  .IconTwo,
  .IconSnake,

  .esIcon-img {
    width: 15vw;
    height: auto;
  }

  .IocnOne{
    width: 23vw;
    height: auto;
  }
  .goodtobuyIcon{
    width: 20vw;
    height: auto;
  }

  .IconFive{
    width: 20vw;
    height: auto;
  }
  .Icontext{
    width: 20vw;
    height: auto;
  }

  /* Adjust button size */
  .clientele-book-btn {
    width: 30vw;
    height: 30vw;
    font-size: 4vw;
    animation: shake360 2s infinite; 
  }

  @keyframes shake360 {
    0% {
      transform: rotate(0deg) translateX(0);
    }
    25% {
      transform: rotate(15deg) translateX(5px);
    }
    50% {
      transform: rotate(-15deg) translateX(-5px);
    }
    75% {
      transform: rotate(10deg) translateX(3px);
    }
    100% {
      transform: rotate(0deg) translateX(0);
    }
  }

  /* Adjust trusted heading */
  .trusted-heading-clientele {
    font-size: 4vw;
    padding: 1vw;
  }

  /* Layer 4 heading */
  .layer-4-heading {
    font-size: 5vw;
    margin-left: 2vw;
    width: 60%;
  }

  /* HR lines */
  .lastContainer-hr-line-left,
  .lastContainer-hr-line-right {
    width: 35vw;
  }
  .description {
    font-size: 5vw;
    line-height: 4vw;
  }
  
  .clientele-main {
    width: 100vw;
    background-color: black;
    color: #fff;
    margin-top: 10vw;
  }

  .last-layer-clientele {
    display: flex;
    align-items: center;
    background-color: black;
    justify-content: center;
    margin: 4vw 0 4vw 0;
  }
}
