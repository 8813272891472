/* Desktop-only Footer Styles
.footer {
  color: #e4e4e4;
  padding: 1vw 1vw;
  font-family: "Arial", sans-serif;
    background: radial-gradient(
        ellipse at bottom,
        rgba(255, 255, 255, 0.09), 
        rgba(255, 255, 255, 0.01) 40%, 
        transparent 80%
      ),
      linear-gradient(to bottom, #000000, #784594 300%);
  background-color: #320039;
  overflow-y: hidden;
}
.footer-top-container {
  display: flex;
  justify-content: space-between;
}

.footer-hr-line {
  width: 70%;
  margin: 0 auto;
  color: #d9d9d9;
  width: 70vw;
  height: 0px;
  border: 0.5px 0px 0px 0px;
  margin-top: 2vw;
}
.footer-unorder-list-container-top {
  display: flex;
  flex-direction: column;
  gap: 0.5vw;
}
.footer-unorder-list-container-top .footer-list-item {
  background: linear-gradient(180deg, #434343 0%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.7vw;
  padding: 0;
}
.footer-unorder-list-container-btm {
  display: flex;
  flex-direction: column;
  gap: 0.5vw;
}
.footer-unorder-list-container-btm .footer-list-item {
  background: linear-gradient(180deg, #434343 0%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.7vw;
  padding: 0px;
}
.footer-contact-info {
  background: linear-gradient(180deg, #434343 0%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Inter;
  font-size: 2.4vw;
  font-weight: 400;
  line-height: 3vw;
  margin-right: 8vw;
}
.copyrights-para {
  text-align: center;
  padding-top: 1vw;
  background: #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Inter;
  font-size: 1.5vw;
  font-weight: 300;
  line-height: 2vw;
}
.footer-btn {
  border: 0.1vw solid #000000;
  box-shadow: 0px 0px 4px 0px #ffffff;
  box-shadow: 0px 0px 12px 0px #ffffff;
  box-shadow: 0px 0px 14px 0px #ffffff;
  padding: 0.7vw 2vw;
  font-size: 1.2vw;
  border-radius: 2vw;
  outline: none;
  color: #ffffff;
  background-color: transparent;
}
.social-media-icons-container {
  display: flex;
  align-items: center;
  gap: 2vw;
  justify-content: flex-end;
  margin-right: 6vw;
  padding-bottom: 1vw;
}
.social-media-icons-container img {
  width: 4vw;
  height: 4vw;
}
.footer-logo {
  width: 16vw;
  height: 5vw;
}
  */ /* Desktop-only Footer Styles */



/* ______________________________________ */

  /* .footer {
  color: #e4e4e4;
  padding: 1vw 1vw;
  font-family: "Arial", sans-serif;
  background: radial-gradient(
      ellipse at bottom,
      rgba(255, 255, 255, 0.09),
      rgba(255, 255, 255, 0.01) 40%,
      transparent 80%
    ),
    linear-gradient(to bottom, #000000, #784594 300%);
  background-color: #320039;
  overflow-y: hidden;
  border: 2px solid red;
}

.footer-top-container {
  display: flex;
  justify-content: space-between;
  border: 1px solid green;
}

.footer-hr-line {
  width: 70%;
  margin: 0 auto;
  color: #d9d9d9;
  width: 70vw;
  height: 0px;
  border: 0.5px 0px 0px 0px;
  margin-top: 1vw;
}

.footer-unorder-list-container-top {
  display: flex;
  flex-direction: column;
  gap: 0.5vw;
}

.footer-unorder-list-container-top .footer-list-item {
  background: linear-gradient(180deg, #434343 0%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.7vw;
  padding: 0;
  cursor: pointer;
}

.footer-unorder-list-container-btm {
  display: flex;
  flex-direction: column;
  gap: 0.5vw;
}

.footer-list-item {
  background: linear-gradient(180deg, #434343 0%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.7vw;
  padding: 0px;
}

.footer-contact-info {
  background: linear-gradient(180deg, #434343 0%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Inter;
  font-size: 2.4vw;
  font-weight: 400;
  line-height: 3vw;
  margin-right: 8vw;
}

.copyrights-para {
  text-align: center;
  padding-top: 1vw;
  background: #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Inter;
  font-size: 1.5vw;
  font-weight: 300;
  line-height: 2vw;
}


.footer_Btn_container{
  margin: 2vw 0;
}

.footer-btn {
  border: 0.1vw solid #000000;
  box-shadow: 0px 0px 4px 0px #ffffff;
  padding: 0.7vw 2vw;
  font-size: 1.2vw;
  border-radius: 2vw;
  outline: none;
  color: #ffffff;
  background-color: transparent;
}

.social-media-icons-container {
  display: flex;
  align-items: center;
  gap: 2vw;
  justify-content: flex-end;
  margin-right: 14vw;
}

.social-media-icons-container img {
  width: 2vw;
  height: 2vw;
}
.company-logo-container{
  width: 48vw;
  border: 1px solid red;
}
.footer_links_main_div{
  display: flex;
  gap: 5vw;
}
.footer-logo {
  width: 16vw;
  height: 5vw;
}

@media screen and (max-width: 799px) {
  .footer {
    padding: 3vw 2vw;
    text-align: center;
  }

  .footer-top-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-logo {
    width: 40vw;
    height: auto;
    margin-bottom: 2vw;
  }
.footer_links_main_div{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10vw;
}
.footer_links_main_div1{
}
.footer_links_main_div1 ul li{
  margin-top: 0.6vw;
}
.main_footer_links{
  display: flex;
}

  .footer-unorder-list-container-top .footer-list-item {
    font-size: 3.5vw;
    margin-bottom: 1vw;
  }

  .footer-list-item {
    font-size: 3.8vw;
  }

  .footer-contact-info {
    font-size: 5vw;
    padding: 2vw 0;
    margin-left: 8vw;
  }

  .footer-btn {
    font-size: 3vw;
    padding: 1.4vw 3vw;
    border-radius: 3vw;
    margin: 3vw 0;
  }

  .footer-hr-line {
    width: 90%;
    margin-top: 3vw;
  }

  .copyrights-para {
    font-size: 4vw;
    line-height: 5vw;
    margin-top: 2vw;
  }

  .social-media-icons-container {
    justify-content: center;
    gap: 5vw;
    margin-top: 4vw;
    margin-left: 60vw;
  }

  .social-media-icons-container img {
    width: 4vw;
    height: 4vw;
  }
} */


/* _______________________________________ */




.footer{
  /* width: 100vw; */
  background: #210379;
  padding:1vw 2vw 1vw 2vw;
  display: flex;
  flex-direction: column;
  gap: 0vw;
  /* height: 25vw; */

/* border: 4px solid red; */
color: #e4e4e4;
    /* padding: 1vw 1vw; */
  font-family: "Arial", sans-serif;
  /* background: radial-gradient(
      ellipse at bottom,
      rgba(255, 255, 255, 0.09),
      rgba(255, 255, 255, 0.01) 40%,
      transparent 80%
    ),
    linear-gradient(to bottom, #000000, #784594 300%); */
  background-color: #4f035a;
  overflow-y: hidden;

}
.top_footer{
  display: flex;
  gap: 15vw;
  font-family: "Arial", sans-serif;

}

.top_footer_1st_div .img{
  width: 13vw;
  height: 4vw;
  background:#fff;
  border-radius: 3vw;
  padding:0.5vw;

}
.img img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.top_footer_1st_div p{
  color: #F3F3F4;
  font-weight: 400;
  font-family: inter;
  font-size: 1.2vw;
  width: 27.78vw;
  margin-top: 1.5vw;
  margin-bottom: 3vw;
  font-family: "Arial", sans-serif;
  /* background: linear-gradient(90deg, #434343 0%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */

}

.tridizi{
  font-size: 1vw;
  margin-top: 1.5vw;
  color: #cacbca;
}

.top_footer_buttons{
  height:3.5vw  ;
  background-color: #F3F3F4;
  border-radius: 3vw;

  width: 29vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.2vw;
  font-family: "Arial", sans-serif;

}

.top_footer_buttons button{
  background-color: #FFF0E8;
    border-radius: 3vw;
    height: 3.3vw;
    border: none;
    outline: none;
    width: 9vw;
    cursor: pointer;
    font-weight: 700;
    font-size: 1.74vw;
    color: #210379;
    box-shadow: 0.2vw 0 0.6vw rgb(122, 121, 121);
  font-family: "Arial", sans-serif;

}

#Hrms_btn{
  background-color: #320039;
  color: #fff;
}


.top_footer_2nd_div{
  display: flex;
  width: 50vw;
  justify-content: space-between;
  font-family: "Arial", sans-serif;

}

.lists{
  display: flex;
  gap: 6vw;
  width: 100vw;


  
}
.unordered_lists{
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1vw;
  /* font-family: inter; */
  font-weight: 600;
  font-family: "Arial", sans-serif;
 


}
.links_to_pages{
  color: #fff;
  font-size: 1.32vw;
  font-family: "Arial", sans-serif;
  background: linear-gradient(180deg, #434343 0%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;


}
.get_a_demo{
  width: 10vw;
  border: none;
  outline: none;
  font-family: poppins;
  color: #4f035a;
  font-weight: 700;
  font-size: 1.35vw;
  margin-top: 0.5vw;
  margin-left: -0.2vw;
  padding: 0.5vw;
  cursor: pointer;
  border-radius: 3vw;
  background-color: #FFF0E8;
  font-family: "Arial", sans-serif;

}


.contact_details{
  display: flex;
  flex-direction: column;
  gap: 0vw;
  /* border: 1px solid red; */
  width: 63vw;
  /* margin-left: 5vw; */
}

.contact_details .span{
  width: 11.15vw;
  /* height: 4.33vh; */
  font-weight: 700;
  font-size: 1.94vw;
  color: white;
  font-family: "Arial", sans-serif;

  background: linear-gradient(180deg, #434343 0%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 0;
  
}
.contact_details p{
  display: flex;
  flex-direction: column;
  gap: 0.5vw;

}
#detailes{
  font-weight: 600;
  font-size: 1.25vw;
  color: #FFF;
  font-family: "Arial", sans-serif;
  background: linear-gradient(180deg, #434343 0%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 0.5vw;

}
.Social_icons_footer{
  margin-top: 0.7vw;
}
/* .socialIcon001{
  padding-right: 1vw;
} */




/* .contact_details .span{

  
}
.contact_details p{
 

}
.contact_details p .mail_details{
 

}
.contact_details p .mail_details_address{
  font-weight: 600;
  font-size: 1.25vw;
  color: #FFF;
  font-family: "Arial", sans-serif;
  background: linear-gradient(180deg, #434343 0%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}
.contact_details p .Address_span{
  font-weight: 600;
  font-size: 1.25vw;
  color: #FFF;
  font-family: "Arial", sans-serif;
  background: linear-gradient(180deg, #434343 0%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

} */


.bottom_footer{
  text-align: center;
  margin-top: 0.2vw;


}
.bottom_footer span{
  color: rgba(255, 255, 255, 0.475);
  font-size: 0.8vw;
  overflow: hidden;
margin-left: -0.5vw;

}
.bottom_footer p{
  font-family: "Arial", sans-serif;
  font-weight: 600;
  font-size: 1.2vw;
  color: white;
  background: linear-gradient(180deg, #434343 0%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: -0.2vw;
}

#address_span{
  padding-left: 1.4vw;
  width: auto;
  font-weight: 600;
  font-size: 1.2vw;
  color: #FFF;
  font-family: "Arial", sans-serif;
  background: linear-gradient(180deg, #434343 0%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: start;
}
#address_span_1{
  padding-left: 1.4vw;
  width: auto;
  font-weight: 600;
  font-size: 1.2vw;
  color: #FFF;
  font-family: "Arial", sans-serif;
  background: linear-gradient(180deg, #434343 0%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: start;
}


@media screen and (min-width: 320px) and (max-width: 720px) {
  
  .top_footer{
    flex-direction: column;
    gap: 0vw;
}
.top_footer_1st_div{
  display: flex;
  flex-direction: column;
  align-items: center;
  
}
.top_footer_1st_div .img{
  width: 40.24vw;
  height: 11.42vw;
  background:#fff;
  border-radius:6vw;
  padding:2vw;

}

.top_footer_1st_div p{
  font-size: 3.5vw;
  width: 90vw;
  text-align: center;
}

.top_footer_buttons{
  width: 80vw;
height: 10vw;
  border-radius: 8vw;


}
.top_footer_buttons button{
  width: 25vw;
  height: 10vw;
  font-size: 3.74vw;
  border-radius: 8vw;

}

.top_footer_2nd_div{
  width: 90vw;
  padding: 2vw;
  margin-top: 2vw;
  flex-direction: column;
  
}
.lists{
  justify-content: center;
  gap: 12vw;
  margin-left: 10vw;
  width: auto;

}
.unordered_lists{
  gap: 0.5vw;
}
.unordered_lists li .links_to_pages{
  font-size: 4vw;
}
.unordered_lists button{
  font-size: 4vw;
  width: 30vw;
  padding: 3vw;
}
.contact_details{
  margin-top: 3vw;
  gap: 0vw;
  margin-left: 20vw;
}
.contact_details .span{
  font-size: 4.5vw;
  width: fit-content;
}
.contact_details p{
  align-items: center;
  gap: 2vw;
}
.contact_details p .mail_details{
  font-size: 4.5vw;
  width: fit-content;
}

.bottom_footer span{
  font-size: 1.7vw;
}
.bottom_footer p{
  font-size: 2.5vw;
}

.bottom_footer{
  text-align: center;
  margin-top: -2.5vw;


}
.get_a_demo{
  border-radius: 6vw;
  font-size: 4vw;
}
.unordered_lists button {
  padding: 2vw;
}

.contact_details p .Address_span{
  font-weight: 600;
  font-size: 4.5vw;
  color: #FFF;
  font-family: "Arial", sans-serif;
  background: linear-gradient(180deg, #434343 0%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}


.contact_details p .mail_details_address{
  font-weight: 600;
  font-size: 4vw;
  color: #FFF;
  font-family: "Arial", sans-serif;
  background: linear-gradient(180deg, #434343 0%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}



.contact_details .span{
  width: 25vw;
  /* height: 4.33vh; */
  font-weight: 700;
  font-size: 4vw;
  color: white;
  font-family: "Arial", sans-serif;

  background: linear-gradient(180deg, #434343 0%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 0;
  
}
/* .contact_details p{
  display: flex;
  flex-direction: column;
  gap: 0.5vw;

} */
#detailes{
  font-weight: 600;
  font-size: 4vw;
  color: #FFF;
  font-family: "Arial", sans-serif;
  background: linear-gradient(180deg, #434343 0%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 0.5vw;
  text-align: start;
  

}

#address_span{
  padding-left: 0vw;
  width: auto;
  font-weight: 600;
  font-size: 4vw;
  color: #FFF;
  font-family: "Arial", sans-serif;
  background: linear-gradient(180deg, #434343 0%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: start;
  margin-left: -14vw;
}
#address_span_1{
  padding-left: 0vw;
  width: auto;
  font-weight: 600;
  font-size: 4vw;
  color: #FFF;
  font-family: "Arial", sans-serif;
  background: linear-gradient(180deg, #434343 0%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: start;
  margin-left: -8vw;
}
.Social_icons_footer{
  margin-top: 0.7vw;
  text-align: start;
}

#Hrms_btn{
  background-color: #320039;
  color: #fff;
  margin-left: 0.1vw;
}



}
