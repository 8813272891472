.terms_conditions_main {
  width: 100vw;
  overflow: hidden;
}

/* .terms_and_conditions_main_heading_shadow {
  width: 30vw;
  background-color: rgba(195, 112, 240, 0.6);
  height: 1.2vw;
  margin-left: 35vw;
  margin-bottom: 5vw;
  margin-top: 4vw;
  box-shadow: 0vw 0vw 4vw 1vw rgba(195, 112, 240, 1);
} */
.terms_conditions_main_h {
  text-align: center;
  font-size: 2.7vw;
  font-family: "Plus Jakarta Sans", sans-serif;
  position: relative;
  bottom: 1.1vw;
  text-shadow: 0px 0px 10px rgba(195, 112, 240, 1),
    0px 0px 20px rgba(195, 112, 240, 1), 0px 0px 40px rgba(195, 112, 240, 1),
    0px 0px 80px rgba(195, 112, 240, 1);
    margin-top: 4vw;
    
}
.terms_conditions_bg_div {
  background: url("../../assets/image 2900 FEATURES.png");
  width: 100vw;
  min-height: 60vw;
  background-repeat: no-repeat;
  margin: 1vw;
  background-size: contain;
  background-position: 10vw;
}
.terms_and_condition-content-div::after {
  content: "";
  position: absolute;
  top: 14vw;
  left: 1.5vw;
  right: 0;
  bottom: 0;
  border-radius: 1vw;
  padding: 0.15vw;
  background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  width: 95vw;
  height: 78vw;

}
.terms_and_condition-content-div {
  margin: 2vw;
  width: 91vw;
  padding: 1vw;
  padding-top: 1.5vw;
  text-align: justify;
}
.terms_and_condition-content-div p {
  color: #ac9f9f;
  font-size: 1.2vw;
}
.terms_and_condition-content-div h1 {
  color: #cbcacb;
  font-size: 2vw;
  margin-top: 1vw;
  font-family: "Roboto";
}
.terms_and_condition_footer_shadow {
  height: 10vw;
  width: 55vw;
  margin-left: 6vw;
  background-color: #c370f0;
  box-shadow: 15vw 2vw 40vw 10vw #c370f0;
}
@media screen and (max-width: 799px) {
  .terms_conditions_main_div{
    overflow: hidden;
  }
  .terms_and_condition-content-div p {
    font-size: 3vw;
    padding: 1vw  0vw 0vw 3vw;
    margin-bottom: 1vw;
  }
  .terms_conditions_bg_div {
    /* min-height: 60vh; */
  }
  .terms_conditions_main {
    min-height: 100vh;
    /* padding: 10px 20px; */
    margin-top: 20vw;
  }
  .terms_and_condition-content-div h1 {
    font-size: 5vw;
    margin-top: 3vw;
    padding: 0 3vw;
  }
  .terms_conditions_main_h {
    margin-top: 8vw;
    font-size: 5vw;
    width: 100%;
  }
  .terms_and_conditions_main_heading_shadow {
    width: 60vw;
    background-color: rgba(195, 112, 240, 0.6);
    height: 2vw;
    /* margin-left: 25vw; */
    margin: 0 auto;
    margin-bottom: 5vw;
    margin-top: 4vw;
    box-shadow: 0vw 0vw 4vw 1vw rgba(195, 112, 240, 1);
  }
  .terms_and_condition-content-div{
    padding-left: 1vw;
    position: relative;
    margin: 2vw 0 0 2vw;
  }
  .terms_and_condition-content-div::after {
    content: "";
    position: absolute;
    top: 0vw;
    left: -1vw;
    right: 0;
    bottom: 0;
    border-radius: 1vw;
    padding: 0.15vw;
    background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    width: 95vw;
    padding: 0.4vw;
    height: auto;
  }
}
