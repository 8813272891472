.Main_Blog_Bg {
    background-color: black;
    width: 100vw;
    /* height: 250vh; */
    height: auto;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
  margin-top: 2vw;
}
.blog-main-heading {
    font-size: 3vw;
    margin-left: 2.2vw;
    margin-top: -1.7vw;
    text-shadow: 0px 0px 10px rgba(195, 112, 240, 1),
      0px 0px 20px rgba(195, 112, 240, 1), 0px 0px 40px rgba(195, 112, 240, 1),
      0px 0px 80px rgba(195, 112, 240, 1);
    margin-top: 1vw;
    margin-bottom: 0vw;
  }
/* Circles   */
.CircleBlog1 {
  position: relative;
  width: 15vw;
  height: 15vw;
  border-radius: 100%;
  background: linear-gradient(180deg, #f7714e 0%, #3343d6 100%);
  top: 55vw;
  left: 83.8vw;
  overflow: hidden;
}

.CircleBlog2 {
  position: relative;
  width: 15vw;
  height: 15vw;
  border-radius: 100%;
  background: linear-gradient(180deg, #f7714e 0%, #3343d6 100%);
  top: 0vw;
  right: 7vw;
}

/* Blogs  */

/* Top Blogs  */

.Blog_Main {
  position: relative;
  border-radius: 20px;
  width: 85vw;
  padding: 0vw 0vw 0vw 0vw;
  background: black;
  margin-top: 3vw;
  background: url("../../assets/image 2900 FEATURES.png");
  width: 95vw;
  /* height: 99vw; */
  height: auto;
  background-repeat: no-repeat;
  background-color: rgba(128, 128, 128, 0.121);
  border-radius: 1vw;
  margin-left: -0.5vw;
  background-size: contain;
  background-position: 1vw;
background-size: cover;
 

}
.Blog_Main::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 1vw;
    padding: 0.15vw;
    background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
}
.Blog_Main div > h3 {
  font-size: 2.7vw;
  /* font-family: "Helvetica Now Display Medium"; */
  margin-left: 6vw;
  padding-top: 30px;
  color: white;
  margin-bottom: 2vw;
}

.Top_Blogs_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

.Blog_Container {
  display: flex;
  justify-content: space-around;
  width: 90%;
  gap: 5vw;
}

.sub_Blog_Container1 {
  display: flex;
  gap: 1.5vw;

}

.sub_Blog_Container1 > img {
  width: 28vw;
}

.Top_Blog_Content h1 {
  font-size: 3vw;
  /* font-family: "Helvetica Now Display Regular"; */
  /* font-weight: 600; */
  color: white;
}

.Top_Blog_Content p {
  font-size: 1.5vw;
  /* font-family: "Helvetica Now Display Regular"; */
  /* font-weight: 400; */
  color: rgb(194, 189, 189);
  width: 40vw;
}

.Top_Blog_Content > p{
    margin-top: 5vw;
}
.Blog_person_Info {
  display: flex;
  align-items: center;
  gap: 1vw;
}

.Blog_person_Info p {
  /* font-weight: 600; */
  /* font-family: "Helvetica Now Display Extra Bold"; */
  color: white;
  font-size: 1vw;
}

.person1,
.person2 {
  display: flex;
  align-items: center;
  gap: 0.3vw;
}

.person1 img {
  width: 1.2vw;
}

.person2 img {
  width: 0.2vw;
}

.Top_Blog_Content > button {
  position: relative;
  width: 9vw;
  height: 3vw;
  font-family: "Helvetica Now Display Medium";
  font-size: 1.4vw;
  /* font-weight: 600; */
  border-radius: 12px;
  border: none;
  background: transparent;
  /* Background color of the button */
  /* z-index: 1; */
  /* Ensure the button content is above the pseudo-element */
  overflow: hidden;
margin-top: 3vw;
cursor: pointer;
}

.Top_Blog_Content > button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  padding: 2px;
  /* Space for the gradient border */
  background: linear-gradient(180deg, #790a83 45%, #00f1ff 88%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  /* Allow button interactions */
}

.sub_Blog_Container2 > section > h2 {
  font-size: 2vw;
  font-family: "Helvetica Now Display Medium";
  /* font-weight: 600; */
  color: rgba(0, 0, 0, 1);
}

.sub_Blog_Container2 > section > p {
  font-size: 1.2vw;
  font-family: "Helvetica Now Display Regular";
  color: rgba(81, 80, 80, 1);
}

/* Recent_Blogs_Container  */

.Recent_Blogs_Container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  gap: 2vw;
  /* border: 2px solid black; */
  flex-wrap: wrap;
  /* overflow-x: scroll; */
 
}

.Single_Blog_Container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 25vw;
  /* height: 41vw; */
  /* gap: 1vw; */
  /* margin-bottom: -10vw; */
}

.BlogImage {
  width: 25vw;
  height: 18vw;
  border-radius: 2vw;
  /* border: 2px solid; */
}

.BlogTitle {
  width: 26vw;
  /* font-family: "Helvetica Now Display Medium"; */
  color: white;
  font-size: 1.8vw;
  height: 5vw;
  /* border: 2px solid; */
  font-weight: 600;
  margin-bottom: -1vw;
}

.BlogContent {
  font-size: 3.2vw;
  font-weight: 500;
  color: #5d5d5d;
  width: 61vw;

  height: 10vh; 
  overflow-y: auto;
  text-align: start;
  word-spacing: 0.1vw;


}

.BlogContent {
  font-size: 1.3vw;
  font-family: "Helvetica Now Display Regular";
  color: #B7B7B7;
  width: 85%;
  height: 10vw;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; 
  scrollbar-width: thin; 
  scrollbar-color: #790a83 #100f0f ;
  padding-right: 2vw; 
}



.BlogContent::-webkit-scrollbar-button{
  display: none !important;
}




.ViewMoreBlogsBTN {
  font-size: 1vw;
  padding: 0.5vw;
  color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  border: none;
  background: linear-gradient(90deg, #790a83 28%, #00f1ff 100%);
  font-family: "Helvetica Now Display Medium";
  cursor: pointer;
  margin-top: 2vw;
}

.Blog_Persons_Info {
  display: flex;
  align-items: center;
  /* gap: 1vw; */

}

.Blog_person_Info .person2{
    margin-left: -31vw;
}
.Blog_person_Info .person1 img{
    margin-top: 1vw;
}
.Blog_person_Info .person2 img{
    margin-top: 1vw;
}

.Blog_Persons_Info p {
  /* font-weight: 600; */
  color: white;
  font-size: 1vw;
  /* font-family: "Helvetica Now Display Extra Bold"; */
  margin-top: -2vw;
}
.Blog_title_div{
  width: 28vw;
  height: 6vw;
  overflow: auto;
  scrollbar-width: none; /* For Firefox, hides the scrollbar */
  -ms-overflow-style: none;
}
.Blog_title_div::-webkit-scrollbar {
  display: none;
}
.Blog_content_div{
  height: auto;
  width: 28vw;
}
.Blog_Persons_Info{
  margin-top: 2vw;
  height: auto;
}
.Blog_Persons_Info img{
    margin-top: -2vw;
}
.Blog_Persons_Info .persons2{
    margin-left: 1vw;
}
.persons1,
.persons2 {
  display: flex;
  align-items: center;
  gap: 0.3vw;
  width: 27vw;
}

.persons1 img {
  width: 1.2vw;
}

.persons2 img {
  width: 0.3vw;
}

.Main_Btn {
  font-size: 1.6vw;
  padding: 1vw;
  color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  border: none;
  background: linear-gradient(90deg, #790a83 28%, #00f1ff 100%);
  font-family: "Helvetica Now Display Medium";
  cursor: pointer;
}

.Main_Button {
  display: flex;
  justify-content: center;
  margin-top: 1vw;
  margin-bottom: 5vw;
}
.Blog_Footer{
    margin-top: 3vw;
}

.application-modal textarea {
  padding: 10px;
  font-family: "Helvetica Now Display Regular";
  background-color: black;
  border: 1px solid rgba(121, 10, 131, 1);
}
.application-modal textarea:focus{
    outline: none;
}
.mySwiper{
  display: block !important;
}
@media only screen and (max-width: 780px) {
  .Main_Blog_Bg {
    margin-top: 20vw;
  }
  .blogs{
    overflow: hidden;
  }
.blog-main-heading{
    font-size: 6vw;
}
  .CircleBlog2 {
    top: 30vw;
  }

  .CircleBlog1 {
    top: 120vw;
  }

  .Blog_Main div > h3 {
    margin-left: 7.5vw;
    padding-top: 30px;
    font-size: 5vw;
    width: 80vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Blog_Main {
    position: relative;
    border-radius: 20px;
    width: 85vw;
    padding: 0vw 0vw 0vw 0vw;
    background: black;
    margin-top: 3vw;
    background: url("../../assets/image 2900 FEATURES.png");
    width: 95vw;
    /* height: 99vw; */
    height: auto;
    background-repeat: no-repeat;
    background-color: rgba(128, 128, 128, 0.121);
    border-radius: 1vw;
    margin-left: -0.5vw;
    background-size: contain;
    background-position: 1vw;
  background-size: cover;
   
  
  }
  .Blog_Main::after{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 1vw;
      padding: 0.15vw;
      background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      pointer-events: none;
  }
  .Blog_Container {
    display: flex;
    justify-content: center;
    width: 90%;
    gap: 5vw;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
  }

  .sub_Blog_Container1 {
    display: flex;
    gap: 1.5vw;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  .sub_Blog_Container1 > img {
    width: 55vw;
  }

  .Top_Blog_Content h1 {
    font-size: 5vw;
    /* margin-left: 20vw; */
    text-align: center;
    width: 79vw;
  }

  .Top_Blog_Content p {
    font-size: 3vw;
    width: 35vw;
    text-align: start;
    padding-left: 0.5vw;
  }
.Top_Blog_Content >p{
    width: 70vw;
    margin-left: 8vw;
    font-size: 3vw;
}
.Blog_person_Info .person2{
    position: relative;
    left: 18vw;
}
  .Blog_person_Info {
    display: flex;
    align-items: center;
    gap: 4vw;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    width: 70vw;
  }

  .Top_Blog_Content > button {
    width: 20vw;
    height: 6vw;
    font-size: 3vw;
    margin-left: 31vw;
}

  .sub_Blog_Container2 > section > h2 {
    font-size: 4.5vw;
  }

  .sub_Blog_Container2 > section > p {
    font-size: 3vw;
  }

  .sub_Blog_Container2 {
    display: flex;
    gap: 15vw;
    /* align-items: center; */
    justify-content: space-between;
    justify-items: center;
  }


  .BlogImage {
    width: 60vw;
    height: 60vw;
    border-radius: 5px;
    /* border: 2px solid; */
    margin-left: 3vw;
  }
  #blog_container_2{
    margin-top: 1vw;
    margin-top: 2vw;
    width: 70vw;
    margin-left: 5vw;
    text-align: center;
  }


  .ViewMoreBlogsBTN {
    font-size: 2.5vw;
    padding: 1.5vw;
    color: rgba(255, 255, 255, 1);
    border-radius: 5px;
    border: none;
    background: linear-gradient(45deg, #790a83 28%, #00f1ff 100%);
    font-family: "Helvetica Now Display Medium";
    cursor: pointer;
  }

  .Blog_Persons_Info {
    display: flex;
    align-items: center;
    gap: 4vw;
    position: relative;
    bottom: 2vw;
  }

  .Blog_Persons_Info p {
    /* font-weight: 600; */
    color: rgba(0, 0, 0, 1);
    font-size: 1vw;
    font-family: "Helvetica Now Display Extra Bold";
  }

  .Blog_Persons_Info {
    display: flex;
    /* flex-wrap: wrap; */
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-left: 0vw;
  }
.Blog_Persons_Info p{
    font-size: 3vw;
    width: 22vw;
}
  .Main_Btn {
    width: 30vw;
    font-size: 3vw;
    padding: 1.5vw;
    margin-top: 1vw;
  }

  .BlogsMainDiv{
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .Recent_Blogs_Container{
    display: flex;
    flex-direction: row;
    /* border: 1px solid red; */
    flex-wrap: nowrap;
    overflow-x: scroll;
    justify-content: space-evenly;
    align-items: center;
    padding-left: 7vw;
    gap: 50vw;
  }
  .Recent_Blogs_Container::-webkit-scrollbar{
    display: none;
  }
  .Single_Blog_Container{
    width: 80vw;
    /* border: 1px solid green; */
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    transition: all 2s ease-in-out;
    column-gap: 20vw;
  }
  .swiper-slide{
    width: 90vw !important;
    justify-content: start !important;
  }

  .BlogImage{
    width: 60vw;
    height: 50vw;
    margin-left: 0;
  }
  .BlogTitle{
    width: 80vw;
    font-size: 5vw;
    height: 17vw;
    overflow: scroll;
  }
  .BlogTitle ::-webkit-scrollbar{
    display: none;
  }
  .Blog_title_div{
    width: 80vw;
    text-align: center;
    height: 17vw;
    overflow: scroll;
  }
  .Blog_title_div ::-webkit-scrollbar{
    display: none;
  }
  .Blog_content_div{
    width: 60vw;
    height: 15vw;
    text-align: center;
    display: flex;
    justify-content: center;
    overflow: scroll;
    align-items: center;
    /* border: 1px solid; */
  }
  .Blog_content_div::-webkit-scrollbar{
    display: none;
  }
  .BlogContent{
 
      width: 80vw; /* Set width only once */
      font-size: 3.2vw;
      height: 15vw; /* Set height only once */
      overflow-y: auto; /* Ensure vertical scrolling is enabled */
      font-weight: 500;
      color: #5d5d5d;
      text-align: start;
      word-spacing: 0.1vw;
      -webkit-overflow-scrolling: touch; 
      scrollbar-width: thin; 
      scrollbar-color: #790a83 #f0f0f0;
      padding-right: 2vw; /* Correct padding-right formatting */
    
    

  }
  .BlogContent ::-webkit-scrollbar{
    display: none;
  }
  .Blog_Persons_Info{
    width: 80vw;
    /* border: 1px solid red; */
    height: 10vw;
    display: flex;
  }
  .persons1{
    width: 40vw;
    height: 5vw;
    gap: 0;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;

  }
  .persons2{
    width: 40vw;
    height: 5vw;
    gap: 0;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

  }
  .Blog_Persons_Info p{
    font-size: 3vw;
    color: #fff;
    margin-left: 1vw;
  }
  #blog_img_1{
    width: 3vw;
    /* border: 1px solid red; */
  }
  #blog_img_2{
    width: 2vw;
    
  }
.blogs_cards_container{
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;

}


.person2 img {
  width: 1.2vw;
}



 }
