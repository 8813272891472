/* last container css code */

.last-container-features {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: black; */
  color: #b9adc2;
  padding-top: 2vw;
}
.last-container-heading-features {
  font-size: 3vw;
  background: linear-gradient(0deg, #ffffff 24.53%, rgba(0, 0, 0, 0) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.last-container-para-features {
  width: 70vw;
  font-size: 1.4vw;
  text-align: center;
  padding: 1vw 1vw;
}
.last-container-button {
  font-weight: 700;
  padding: 0.5vw 2vw;
  font-size: 1.4vw;
  font-weight: 600;
  background: linear-gradient(90deg, #790a83 28%, #00f1ff 100%);
  color: #fff;
  font-weight: bold;
  border: 0.1vw solid black;
  margin-bottom: 1.4vw;
  z-index: 10;
  border-radius: 0.5vw;
}
.outline {
  width: 13.3vw;
  height: 2.8vw;
  border: 0.2vw solid #fff;
  position: relative;
  top: 2vw;
  z-index: 11;
  right: 0.8vw;
  border-radius: 0.6vw;
}
@media screen and (max-width: 799px) {
  .last-container-heading-features {
    font-size: 5vw;
  }
  .last-container-para-features {
    width: 80vw;
    font-size: 6vw;
    text-align: justify;
  }
  .last-container-button {
    font-size: 4vw;
    padding: 1.4vw 5vw;
  }
  .outline {
    width: 36vw;
    height: 7.4vw;
    top: 6vw;
    left: -2vw;
    border: 0.3vw solid #fff;
  }
  #para_container{
    width: 80vw;
    text-align: center;
  }
  .last-container-features {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: black; */
    color: #b9adc2;
    padding-top: 10vw;
  }
}
