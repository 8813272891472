.PartnerProgram-bg {
  width: 100vw;
  /* min-height: 0vh; */
  /* background-color: black; */
}
/* .PartnerProgram_main_heading_shadow{
  width: 20vw;
  background-color: rgba(195, 112, 240, 0.6);
  height: 1.2vw;
  margin-left: 40vw;
  margin-bottom: 5vw;
  margin-top: 4vw;
  box-shadow: 0px 0px 3vw 1vw rgba(195, 112, 240, 1);
} */
.PartnerProgram_main_heading_shadow h1 {
  text-align: center;
  position: relative;
  bottom: -1.7vw;
  font-size: 2.5vw;
  text-shadow: 0px 0px 10px rgba(195, 112, 240, 1),
    0px 0px 20px rgba(195, 112, 240, 1), 0px 0px 40px rgba(195, 112, 240, 1),
    0px 0px 80px rgba(195, 112, 240, 1);
  margin-top: 3vw;
}

/* layer-1 code */
.PartnerProgram-layer1 {
  margin: 4vw;
  width: 90vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.3vw;
  color: #fff;
}
.text-content-layer-1 {
  width: 50vw;
}
.partnerProgram_side{

  /* background-color: #790a83; */
}
.partnerProgram_side p{
  font-size: 1.2vw;
  /* background: linear-gradient(180deg, #790a83 45%, #00f1ff 88%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: #fff;
  position: fixed;
  top: 8vw;
  left: 0vw;
  z-index: 100;
padding: 0.5vw 1.5vw;
border-top-right-radius: 2vw;
border-bottom-right-radius: 2vw;

background-color: #790a83;
}
.text-content-layer-1 h1 {
  font-size: 3.93vw;
  margin-bottom: 1vw;
}

.text-content-layer-1 p {
  font-size: 1.31vw;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 1.2vw;
  width: 40vw;
}
.button-container-layer-1-pp {
  /* margin-top: 2vw; */
}
.pp_button_outline {
  width: 12.3vw;
  height: 2.6vw;
  border: 0.2vw solid #fff;
  position: relative;
  top: 2.2vw;
  z-index: 11;
  left: -0.6vw;
  border-radius: 0.6vw;
  cursor: pointer;
}
.btn-layer-1-left {
  padding: 0.7vw 1.3vw;
  border: none;
  background: linear-gradient(90deg, #790a83 28%, #00f1ff 100%);
  color: white;
  cursor: pointer;
  border-radius: 0.6vw;
  margin-right: 1vw;
  font-size: 1.1vw;
  cursor: pointer;
}

.btn-layer-1-right {
  padding: 0.7vw 1.4vw;
  /* border: 0.1vw solid #fff; */
  box-shadow: 0vw 0vw 0.5vw 0vw #ffffff;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  border-radius: 0.6vw;
  font-size: 1.1vw;
}

.layer-pp-image-container {
  /* width: 36vw;
  height: 19.7vw; */
  /* border: 0.1vw solid #fff; */
}
.layer-pp-image-container img{
  width: 36vw;
  height: 19.7vw;
}
/* layer-2 */
.crm-container.layer-2 {
  padding: 2vw;
  color: white;
  text-align: center;
  font-family: "Arial", sans-serif;
}
/* Header */
.crm-header.layer-2 {
  font-size: 2.62vw;
  margin-bottom: 2.6vw;
  color: #fff;
}
/* Features Section */
.features-box.layer-2 {
  display: flex;
  justify-content: space-between;
  border-radius: 1vw;
  padding: 1.3vw;
  margin-bottom: 2.6vw;
  background: rgba(255, 255, 255, 0.05);
  position: relative;
}
.features-box.layer-2::after {
  content: "";
  position: absolute;
  /* top: 57vw; */
  top: 0;
  left: 0vw;
  right: 0;
  bottom: 0;
  border-radius: 1vw;
  padding: 0.15vw;
  background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  width: 95.7vw;
  height: auto;
}
.pp_group_icon_21 {
  width: 3vw;
  margin-right: 1vw;
}
.feature-item.layer-2 {
  width: 25vw;
  text-align: left;
}
.icon.layer-2 {
  font-size: 2.5vw;
  margin-bottom: 1vw;
}
.feature-title.layer-2 {
  font-size: 1.4vw;
  margin-bottom: 0.6vw;
  color: rgba(255, 255, 255, 1);
  font-weight: 600;
}
.feature-text.layer-2 {
  font-size: 1.1vw;
  line-height: 1.5;
}
.pp_bg_image {
  background: url(../../assets/image\ 2910.png);
  background-repeat: no-repeat;
  /* border-radius: 1vw; */
  /* margin-left: -0.5vw; */
  background-size: contain;
  background-position: 20vw;
}
/* Partner Section */
.partner-section.layer-2 {
  text-align: center;
  margin-top: 0vw;
}

.partner-header.layer-2 {
  font-size: 3vw;
  margin-bottom: 2vw;
  font-weight: 600;
}

.partner-benefits.layer-2 {
  display: flex;
  justify-content: space-around;

}

.benefit-item.layer-2 {
  width: 25vw;
  text-align: center;
}

.benefit-title.layer-2 {
  font-size: 1.4vw;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 1vw;
}

.benefit-text.layer-2 {
  font-size: 1.2vw;
  color: rgba(255, 255, 255, 0.8);
}
.icon.layer-2 img {
  width: 3vw;
}
.icon.layer-2 {
  font-size: 3vw;
  margin-bottom: 1vw;
}
/* layer-3 css code  */
/* Container styling */
.partner-container.layer-3-pps {
  margin: 2vw 0vw;
  padding: 1vw;
  color: #fff;
  text-align: center;
  font-family: "Arial", sans-serif;
}

/* Header styling */
.partner-header.layer-3-pps {
  font-size: 3vw;
  font-weight: 600;
  margin-bottom: 1vw;
}

/* Subtext styling */
.partner-subtext.layer-3-pps {
  font-size: 2.2vw;
  width: 60vw;
  text-align: center;
  font-weight: 500;
  margin-left: 20vw;
  margin-bottom: 1.8vw;
  color: rgba(255, 255, 255, 0.8);
}

/* Button container */
.partner-buttons.layer-3-pps {
  display: flex;
  justify-content: center;
  gap: 2vw;
}

/* Sign up button styling */
.sign-up-btn.layer-3-pps {
  padding: 0.6vw 1.1vw;
  background: linear-gradient(90deg, #790a83 28%, #00f1ff 100%);
  color: #fff;
  border: none;
  border-radius: 0.2vw;
  font-size: 1.1vw;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sign-up-btn.layer-3-pps:hover {
  opacity: 0.9;
}

/* Demo button styling */
.demo-btn.layer-3-pps {
  padding: 0.8vw 1.2vw;
  background-color: transparent;
  color: #fff;
  border: 0.1vw solid #fff;
  border-radius: 0.4vw;
  box-shadow: 0vw 0vw 0.2vw 0vw rgba(255, 255, 255, 1),
    0vw 0vw 1vw 0vw rgba(255, 255, 255, 1),
    0vw 0vw 1vw 0vw rgba(255, 255, 255, 1);
  font-size: 1.1vw;
  cursor: pointer;
  transition: all 0.3s ease;
}

.demo-btn.layer-3-pps:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.row-pp {
  display: flex;
  align-items: flex-end;
  color: rgba(255, 255, 255, 1);
}


.application-modal_pp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  font-family: "Helvetica Now Display Medium";
}
.application-modal-content_pp {
  background-color: rgb(0, 0, 0);
  padding: 20px;
  border-radius: 8px;
  width: 90vw;
  max-width: 70%;
  max-height: 80%;
  overflow-y: auto;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
  font-family: "Helvetica Now Display Medium";
}
.application-modal-content_pp .close-button_pp {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
  color: black;
  font-family: "Helvetica Now Display Extra Bold";
}
.application-modal-content_pp form {
  display: flex;
  flex-direction: column;
}

.application-modal-content_pp form label {
  margin-top: 10px;
  margin-bottom: 5px;
}

.application-modal-content_pp form input {
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  border: 1px solid rgba(121, 10, 131, 1);
  background-color: black;
}
.application-modal-content_pp form input:focus {
  border: 1px solid rgba(121, 10, 131, 1);
  outline: none;
}
.application-modal-content_pp ::after {
  content: "";
  position: absolute;
  top: 0vw;
  left: 0vw;
  right: 0;
  bottom: 0;
  border-radius: 0.5vw;
  padding: 0.15vw;
  background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  /* width: 90vw; */
  /* height: 50vw; */
}
.application-modal-content_pp form button {
  padding: 10px;
  background: linear-gradient(90deg, #790a83 28%, #00f1ff 100%);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  font-family: "Helvetica Now Display Extra Bold";
  font-size: 1.6vw;
  font-weight: bold;
}
/* medie query code */
@media screen and (min-width: 320px) and (max-width: 720px) {
  .partner{
    overflow: hidden;
  }
  .PartnerProgram_main_heading_shadow h1 {
    font-size: 5vw;
    margin-top: -3vw;
    margin-bottom: 2vw;
  }
  .PartnerProgram-layer1 {
    flex-direction: column-reverse;
    height: 106vw;
   margin: 0vw 4vw 14vw 4vw;
  }
  .text-content-layer-1 {
    width: 70vw;
  }
  .layer-pp-image-container {
    width: 70vw;
    height: 40vw;

    margin-top: 2vw;
  }
  .features-box.layer-2 {
    margin-bottom: 2.6vw;
    flex-direction: column;
    align-items: center;
    gap: 2vw;
    width: 90vw;
        margin-left: 3.5vw; 
  }
  .feature-item.layer-2 {
    width: 70vw;
  }
  .partner-benefits.layer-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vw;
  }
  .benefit-item.layer-2 {
    width: 80vw;
  }
  .text-content-layer-1 p {
    font-size:3vw;
    color: rgba(255, 255, 255, 1);
    margin-bottom: 0vw;
    width: 70vw;
    text-align: start;
  }
  .text-content-layer-1 h1 {
    font-size: 5vw;
    width: 80vw;
    margin-top: 4vw;
    /* margin-bottom: 4vw; */
  }
  .btn-layer-1-left {
    /* padding: 0.7vw 1.3vw; */
    padding: 0px;
    border: none;
    border-radius: 0.6vw;
    margin-right: 3vw;
    font-size: 3vw;
    width: 28vw;
    height: 6vw;
  }
  .btn-layer-1-right {
    font-size: 3vw;
    width: 30vw;
    height: 6vw;
  }
  .pp_button_outline {
    width: 28vw;
    height: 6vw;
    border: 0.2vw solid #fff;
    position: relative;
    top: 5vw;
    z-index: 11;
    left: -1.5vw;
    border-radius: 0.5vw;
  }

  .crm-header.layer-2 {
    font-size: 5vw;
    margin-bottom: 3vw;
  }
  .row-pp {
    align-items: center;
  }
  .icon.layer-2 img {
    width: 8vw;
  }
  .feature-title.layer-2 {
    font-size: 4vw;
    margin-bottom: 0.6vw;
    margin-top: 3vw;
    /* font-weight: 600; */
  }
  .feature-text.layer-2 {
    font-size: 3vw;
    line-height: 1.5;
    margin-bottom: 4vw;
    text-align: justify;
    /* word-spacing: -1.8vw; */
  }
  .partner-header.layer-2 {
    font-size: 5vw;
    margin-bottom: 3vw;
  }
  .benefit-item.layer-2 {
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .benefit-title.layer-2 {
    font-size: 5vw;
    color: rgba(255, 255, 255, 1);
    margin-bottom: 1vw;
  }
  .benefit-text.layer-2 {
    font-size: 3vw;
    color: rgba(255, 255, 255, 0.8);
    text-align: start;
    margin-bottom: 3vw;
    position: relative;
    width: 60vw;
    text-align: center;
  }
  .features-box.layer-2::after {
    content: "";
    position: absolute;
    top: 0vw;
    /* left: 2vw; */
    right: 0;
    bottom: 0;
    border-radius: 1vw;
    padding: 0.5vw;
    background: linear-gradient(180deg, #00f1ff 0%, #790a83 50%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    width: auto;
    height: auto;
  }
  .partner-header.layer-3-pps{
    font-size: 5vw;
    margin-left: 3vw;
  }
  .partner-subtext.layer-3-pps{
    font-size: 3vw;
    width: 80vw;
    margin-left: 10vw;
    line-height: 5vw;
  }
  .sign-up-btn.layer-3-pps{
    font-size: 3vw;
    height: 7vw;
    width: 28vw;
    border-radius: 1vw;
  }
  .demo-btn.layer-3-pps{
    font-size: 3vw;
    width: 30vw;
    height: 7vw;
    border-radius: 1vw;
  }
  .partner-section{
    text-align: center;
  }
  .partner-buttons.layer-3-pps{
    margin-top: 4vw;
  }

  .layer-pp-image-container img{
    width: 70vw;
    height: 50vw;
  }

  .partner-section.layer-2 {
    text-align: center;
    margin-top: 4vw;
  }

  .button-container-layer-1-pp {
    margin-top: -3vw;
  }

  .PartnerProgram-bg {
    width: 100vw;
   margin-top: 26vw;
  }

  .partnerProgram_side p{
    font-size: 3vw;
    /* background: linear-gradient(180deg, #790a83 45%, #00f1ff 88%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    color: #fff;
    position: fixed;
    top: 20vw;
    left: 0vw;
    z-index: 100;
  padding: 1vw 2vw;
  border-top-right-radius: 3vw;
  border-bottom-right-radius: 3vw;
  
  background-color: #790a83;
  }
}
